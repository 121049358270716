import { InspectionRoutes } from '@linkdev/inspection-library';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { FormTemplateComponent } from './form-template/form-template.component';
import { PostActivationComponent } from './post-activation/post-activation.component';
import { SearchComponent } from './search/search.component';
import { SettingsMenuComponent } from './header-footer/header/components/settings-menu/settings-menu.component';
import { HandleLogin } from './handleLogin/handle-login.component';
import { FormIOComponent } from './form-io/form-io.component';
import { ServiceManagementRoutes } from '@linkdev/service-management-library';
import { AccessManagementRoutes } from '@linkdev/access-manage-library';
import { AppointmentsRoutes } from '@linkdev/appointment-library';
import { LookupsRoutes } from '@linkdev/lookup-library';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { OnlinePaymentStatusComponent } from '@linkdev/portals-shared';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: `${ServiceManagementRoutes.baseRoute}`,
    loadChildren: () =>
      import('./portal-services-library/portal-services.module').then(
        (m) => m.PortalServicesModule
      ),
  },
  {
    path: `${AccessManagementRoutes.baseRoute}`,
    loadChildren: () =>
      import('./portal-profile-library/portal-profile.module').then(
        m => m.PortalProfileModule
      )
  },
  {
    path: `${InspectionRoutes.baseRoute}`,
    loadChildren: () =>
      import('./portal-inspection-library/portal-inspection-library.module')      .then(
        m => m.PortalInspectionLibraryModule),
    data: { logo: '../assets/images/logo-inspection.png' }
  },
  {
    path: 'postactivation',
    component: PostActivationComponent,
  },
  {
    path: `${LookupsRoutes.publicBaseRoute}`,
    loadChildren: () =>
      import('./portal-lookup-library/portal-lookup-library.module').then(
        m => m.PortalLookupLibraryModule
      )
  },
  {
    path: 'notifications',
    loadChildren: () => import('./portal-notifications-library/portal-notifications-library.module')
      .then(m => m.PortalNotificationsLibraryModule)
  },
  {
    path: 'settings-menu',
    component: SettingsMenuComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'template',
    component: FormTemplateComponent
  },
  {
    path: `${AppointmentsRoutes.baseRoute}`,
    loadChildren: () =>
      import('./portal-appointments-library/portal-appointments.module').then(
        m => m.PortalAppointmentModule
      )
  },
  {
    path: 'handlelogin',
    component: HandleLogin,
  },
  {
    path: 'formIo',
    component: FormIOComponent
  },
  {
    path: 'error',
    component: ErrorPageComponent,

  },
  {
    path: 'onlinePaymentStatus',
    component: OnlinePaymentStatusComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
