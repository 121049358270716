import { NgModule } from "@angular/core";
import { PublicLookupsRoutingModule, LookupsService, LOOKUP_LIBRARY_CONFIG } from "@linkdev/lookup-library";
import { AppConfigService } from "../core/config/app-config.service";
import { Constants } from "../data/constants/constants";

@NgModule({
    declarations: [],
    imports: [
        PublicLookupsRoutingModule
    ],
    providers: [
        LookupsService,
        {
            provide: LOOKUP_LIBRARY_CONFIG, useFactory: () => {
                return {
                    baseUrl: AppConfigService.settings.accessManagementUrl,
                    // portalUrl: AppConfigService.settings.portalUrl,
                    // lookupLibraryUrl: AppConfigService.settings.lookupLibraryUrl,
                    textboxMaxLength: Constants.maxLengthConfig.textboxDefaultLength,
                    textAreaMaxLength: Constants.maxLengthConfig.textAreaDefaultLength,
                    breadcrumbs: Constants.breadcrumbs,
                    lookupsApi: AppConfigService.settings.lookupsApi,
                    maxLengthConfig: Constants.maxLengthConfig
                }
            }
        }]
})
export class PortalLookupLibraryHelperModule { }