import { Component, OnInit } from '@angular/core';
import { UserService } from '@linkdev/shared-library';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-post-activation',
  templateUrl: './post-activation.component.html',
})
export class PostActivationComponent implements OnInit {
  translation: any;

  constructor(public userService: UserService, private translateService: TranslateService) {
    this.translateService.get("postActivation").subscribe((trans) => {
      this.translation = trans;
      document.title =  this.translation.activationSuccessfull
    })
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().subscribe(loggedIn => {
      if (loggedIn) {
        this.userService.logout(location.href).subscribe();
      }
      else {
        setTimeout(() => this.userService.login().subscribe(), 3000)
      }
    })
  }
}