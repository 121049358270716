
<div class="mobile-search d-lg-none">
  <a routerLink="/" class="d-block close-btn lib-icon-close"></a>
  <div class="general-search-bar">
    <input
      type="text"
      name="name"
      value=""
      class="search-input"
      placeholder="{{ 'header.search' | translate }}"
      tabindex="-1"
    />
    <a href="#" class="search-link header-btn" tabindex="-1">
      <span class="icon icon-search c-highlight-light"></span>
    </a>
  </div>
  <div class="search-content">
    <div class="placeholder">
      <p>
        <span class="icon lib-icon-searchstate"></span>
      </p>
      <p>{{ 'search.searchMessage' | translate }}</p>
    </div>
  </div>
</div>

