import { DynamicProfileComponentsDictionary, ProfileComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { CorporateProfileComponent, PersonalProfileComponent } from '@linkdev/access-manage-library';

@Injectable()
export class ProfileComponentsDictionary implements DynamicProfileComponentsDictionary {
    public getDynamicComponents(): ProfileComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: ProfileComponentItem[] = [
        new ProfileComponentItem(PersonalProfileComponent, "PersonalProfileComponent"),
        new ProfileComponentItem(CorporateProfileComponent, "CorporateProfileComponent")
    ]

}
