<div class="redirect-page">
    <div class="redirect-page_text">
        <p class="redirect_large-txt">{{translation?.holdOn || 'Hold On!'}}</p>
        <p class="redirect-small-txt">{{translation?.activationRedirection || "You're being redirected to login page"}}</p>
        <div class="dots-wrp">
            <div class="dot dot-1"></div>
            <div class="dot dot-2"></div>
            <div class="dot dot-3"></div>
            <div class="dot dot-4"></div>
          </div>
    </div>
<!--    <div class="redirect-page_logo">-->
<!--        <a  title="homepage" alt="homepage" href="#"><img src="../../assets/images/logo.png"></a>-->
<!--    </div>-->
</div>
