import { Component, OnInit } from '@angular/core';
import { UserService } from '@linkdev/shared-library';

@Component({
  selector: 'handle-login',
  templateUrl: './handle-login.component.html',
})
export class HandleLogin implements OnInit {
  translation: any;

  constructor(public userService: UserService) {
    this.userService.login().subscribe();
  }

  ngOnInit(): void {

  }
}