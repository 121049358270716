import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentComponent } from './cookie-consent.component';
import { CoreModule } from '@linkdev/shared-library';

@NgModule({
  declarations: [CookieConsentComponent],
  imports: [
    CommonModule,
    CoreModule,
  ],
  exports: [CookieConsentComponent]
})
export class CookieConsentModule {

  /**
   *
   */
  constructor() {


  }
}
