import { Injectable } from "@angular/core";
import { DynamicRequestOutputComponentsDictionary, RequestOutputComponentItem } from '@linkdev/shared-library';
import { DefaultRequestOutputComponent } from "@linkdev/service-management-library";

@Injectable()
export class DynamicRequestOutputDictionary implements DynamicRequestOutputComponentsDictionary {
    public getDynamicComponents(): RequestOutputComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: RequestOutputComponentItem[] = [
        new RequestOutputComponentItem(DefaultRequestOutputComponent, "DefaultRequestOutputComponent"),
    ]

}
