import { Component, OnInit } from '@angular/core';
// import { UserService } from '../../../../core/services/user.service';
import { Helper, UserService } from '@linkdev/shared-library';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css']
})
export class LoginButtonComponent implements OnInit {

  constructor(private _userService : UserService) { }

  ngOnInit(): void {
  }

  onClick()
  {
    this._userService.login().subscribe(_ => {});
  }
}
