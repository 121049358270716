<div class="cookies-popup" *ngIf="showPopup">
  <div class="container">
    <div class="cookies-popup-wrp">
      <div class="popup-text">
        <h3 class="title">{{ ('cookies.title' | translate) || config.title }}</h3>
        <p class="brief">{{ ('cookies.messageText' | translate) || config.messageText }}</p>
      </div>
      <button (click)="onAllowClick()" class="btn btn-primary popup-button">
        {{ ('cookies.buttonText' | translate) || config.buttonText }}
      </button>
    </div>
  </div>
</div>