import { DynamicWorkspaceCardComponentsDictionary, WorkspaceCardComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { MyAppointmentsComponent } from '@linkdev/appointment-library';

@Injectable()
export class DynamicWorkspaceCardsDictionary implements DynamicWorkspaceCardComponentsDictionary {
    constructor() {

    }

    public getDynamicComponents(): WorkspaceCardComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: WorkspaceCardComponentItem[] = [
        new WorkspaceCardComponentItem(MyAppointmentsComponent, "my-appointments", "upcomingAppointments", null, `${AppConfigService.settings.appointmentApi}/SlotBooking/upcomming-appointments-count`, false, 0, "my-appointments", null, false),
        new WorkspaceCardComponentItem(null, null, "bookAppointment", "lib-icon-book-appoint text-primary", `${AppConfigService.settings.publicPortalUrl}/#/appointment`, true, null, null, null, false)
    ]

}
