import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigManagement_CONFIG } from '@linkdev/config-management-library';
import { AppConfigService } from '../core/config/app-config.service';
import { Constants } from '../data/constants/constants';
import { GoogleMapsComponent } from '@linkdev/portals-shared';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [{
    provide: ConfigManagement_CONFIG, useFactory: () => {
      return {
        baseUrl: AppConfigService.settings.configurationsApiUrl,
        portalUrl: AppConfigService.settings.publicPortalUrl,
        lookupLibraryUrl: AppConfigService.settings.lookupsApi,
        accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
        documentManagmentUrl: AppConfigService.settings.documentManagementUrl,
        textboxMaxLength: Constants.maxLengthConfig.textboxDefaultLength,
        textAreaMaxLength: Constants.maxLengthConfig.textAreaDefaultLength,
        breadcrumbs: Constants.breadcrumbs,
        lookupsApi: AppConfigService.settings.lookupsApi,
        maxLengthConfig: Constants.maxLengthConfig,
        mapType: GoogleMapsComponent,
      };
    }
  }]
})
export class PortalConfigurationsLibraryModule { }
