import { DynamicWorkspaceTabComponentsDictionary, WorkspaceTabComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { MyInspectionsComponent,MyIncidentsComponent } from '@linkdev/inspection-library';
import { PublicProfileService } from '@linkdev/access-manage-library';
import { Subject } from 'rxjs';
import { MyAppointmentsComponent } from '@linkdev/appointment-library';
import { MyRequestsComponent, MyTasksComponent, MyPaymentsComponent, MyOutputsComponent } from '@linkdev/service-management-library';

@Injectable()
export class DynamicWorkspaceComponentsDictionary implements DynamicWorkspaceTabComponentsDictionary {

    constructor(private _publicProfileService: PublicProfileService) {
    }

    public getDynamicComponents(): WorkspaceTabComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: WorkspaceTabComponentItem[] = [
        new WorkspaceTabComponentItem(MyRequestsComponent, 'requests', 'requests'),
        new WorkspaceTabComponentItem(MyTasksComponent, 'tasks', 'tasks'),
        new WorkspaceTabComponentItem(MyPaymentsComponent, 'payments', 'payments'),
        new WorkspaceTabComponentItem(MyOutputsComponent, 'MyOutputsComponent', 'outputs'),
        new WorkspaceTabComponentItem(MyInspectionsComponent, "MyInspectionsComponent", "inspections",
            (user) => {
                let result = new Subject<boolean>();
                this._publicProfileService.getProfileSummary(user.profile.sub, false)
                    .subscribe(summary => {
                        this._publicProfileService.getExtendedProfileSummaryByProfileId(summary.data.id, false)
                            .subscribe(extendedSuammary => {
                                result.next(extendedSuammary.data.activityId != undefined);
                            }, () => {
                                result.next(false);
                            });
                    }, () => {
                        result.next(false);
                    });
                return result.asObservable();
            }, false),

            new WorkspaceTabComponentItem(MyIncidentsComponent, "MyIncidentsComponent", "incidents",
            (user) => {
                let result = new Subject<boolean>();
                this._publicProfileService.getProfileSummary(user.profile.sub, false)
                    .subscribe(summary => {
                        this._publicProfileService.getExtendedProfileSummaryByProfileId(summary.data.id, false)
                            .subscribe(extendedSuammary => {
                                result.next(extendedSuammary.data.activityId != undefined);
                            }, () => {
                                result.next(false);
                            });
                    }, () => {
                        result.next(false);
                    });
                return result.asObservable();
            }, false),
        new WorkspaceTabComponentItem(MyAppointmentsComponent, "my-appointments", "appointments", null, false)
    ];
}
