import { Injectable } from "@angular/core";
import { DynamicProfileMenuComponentsDictionary, ProfileMenuComponentItem } from '@linkdev/shared-library';
import { MyTasksComponent, MyRequestsComponent } from '@linkdev/service-management-library';

@Injectable()
export class ProfileMenuComponentsDictionary implements DynamicProfileMenuComponentsDictionary {
    getDynamicComponents(): ProfileMenuComponentItem[] {
        return this._dynamicComponents;
    }
     
    private _dynamicComponents: ProfileMenuComponentItem[] = [
        //new ProfileMenuComponentItem(MyRequestsComponent, "MyRequestsComponent", "requests", "lib-icon-Requests", null, 'my-requests'),
        //new ProfileMenuComponentItem(MyTasksComponent, "MyTasksComponent", "tasks", "lib-icon-Tasks", null, 'my-tasks')
    ];

    

}
