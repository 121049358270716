import {  Component, OnInit } from '@angular/core';
import { Formio } from '@linkformbuilder/formiojs';

@Component({
  selector: 'app-form-io',
  templateUrl: './form-io.component.html',
  styleUrls: ['./form-io.component.css']
})
export class FormIOComponent implements  OnInit{
  constructor() {

}

  ngOnInit(): void {
    // Formio.icons = 'fontawesome';
    this.test1();
  }
  test() {
    Formio.createForm(document.getElementById('formio2'), {
      components: [
        {
          "display": "form",

          label: 'test',
          key: 'children',
          type: 'datagrid',

          components: [
            {
              "type": "textfield",
              "validate": {},
              "persistent": true,
              "unique": false,
              "protected": false,
              "defaultValue": "",
              "multiple": false,
              "suffix": "",
              "prefix": "",
              "placeholder": "Enter the company name",
              "key": "name",
              "label": "Name",
              "inputMask": "",
              "inputType": "text",
              "tableView": true,
              "input": false,
              "autofocus": false,
              "hidden": false,
              "clearOnHide": true,
              "spellcheck": true,
              "conditional": {
              "show": null,
              "when": null,
              "eq": ""
              }
              },
              {
              "type": "email",
              "persistent": true,
              "unique": false,
              "protected": false,
              "defaultValue": "",
              "suffix": "",
              "prefix": "",
              "placeholder": "Enter the company email",
              "key": "email",
              "label": "Email",
              "inputType": "email",
              "tableView": true,
              "input": false,
              "autofocus": false,
              "hidden": false,
              "clearOnHide": true,
              "kickbox": {
              "enabled": false
              }
              },
              {
              "type": "phoneNumber",
              "validate": {
              "required": false
              },
              "persistent": true,
              "unique": false,
              "protected": false,
              "multiple": false,
              "suffix": "",
              "prefix": "",
              "placeholder": "Enter the company phone number",
              "key": "phoneNumber",
              "label": "Phone Number",
              "inputMask": "(999) 999-9999",
              "tableView": true,
              "input": false,
              "autofocus": false,
              "inputType": "tel",
              "hidden": false,
              "defaultValue": "",
              "clearOnHide": true
              },
              {
                "type": "address",
                "validate": {
                "required": false
                },
                "persistent": true,
                "unique": false,
                "protected": false,
                "multiple": false,
                "suffix": "",
                "prefix": "",
                "placeholder": "Enter the company address",
                "key": "address",
                "label": "Address",
                "tableView": true,
                "input": false,
                "autofocus": false,
                "inputType": "tel",
                "hidden": false,
                "defaultValue": "",
                "clearOnHide": true,
                "map": {
                  "region": "",
                  "key": ""
                  }
                }
              // ,
              // {
              // "type": "button",
              // "theme": "primary",
              // "disableOnInvalid": true,
              // "action": "submit",
              // "block": false,
              // "rightIcon": "",
              // "leftIcon": "",
              // "size": "md",
              // "key": "submit",
              // "tableView": false,
              // "label": "Submit",
              // "input": true,
              // "autofocus": false
              // }
          ]
        }
      ]
    }).then(function(form) {

      form.submission = {

        data: {
          children: [
            {
              "address": {
                "types": [],
                "place_id": "ChIJR-r1imB6kFQRs9zHNjlda2A",
                "partial_match": true,
                "geometry": {},
                "formatted_address": "324th Pl SE, Fall City, WA 98024, USA",
                "address_components": []
                },
                "phoneNumber": "2342342432",
                "email": "abc@example.com",
                "name": "ABC Org"
            },
            {
              "address": {
                "types": [],
                "place_id": "Eig5ODIzIE1haW4sIERlIFZhbGxzIEJsdWZmLCBBUiA3MjA0MSwgVVNB",
                "partial_match": true,
                "geometry": {},
                "formatted_address": "9823 Main, De Valls Bluff, AR 72041, USA",
                "address_components": []
                },
                "phoneNumber": "9879872232",
                "email": "acme@example.com",
                "name": "ACME Inc."
            },
            {
                "phoneNumber": "9879872232",
                "email": "acme@example.com",
                "name": "ACME."
            },
            {"name": "Hooli",
            "email": "Hooli@example.com",
            "phoneNumber": "(987) 239-8239",

          }
          ]
        }
      };
    });
  }
  test1() {
    Formio.createForm(document.getElementById('formio2'), {

      "display": "form",
      "components": [
          {
              "legend": "test",
              "key": "fieldset",
              "type": "fieldset",
              "label": "",
              "input": false,
              "tableView": false,
              "components": [
                  {
                      "label": "Table",
                      "cloneRows": true,
                      "cellAlignment": "left",
                      "key": "table",
                      "properties": {
                          "keyasd": "asda",
                          "keya": "ad"
                      },
                      "conditional": {
                          "show": true,
                          "eq": "sadd dasd"
                      },
                      "attributes": {
                          "keyda": "da",
                          "key": "dadada"
                      },
                      "type": "table",
                      "numRows": 1,
                      "numCols": 4,
                      "input": false,
                      "tableView": false,
                      "rows": [
                          [
                              {
                                  "components": [
                                    {
                                      "address": {
                                        "types": [],
                                        "place_id": "ChIJR-r1imB6kFQRs9zHNjlda2A",
                                        "partial_match": true,
                                        "geometry": {},
                                        "formatted_address": "324th Pl SE, Fall City, WA 98024, USA",
                                        "address_components": []
                                        },
                                        "phoneNumber": "2342342432",
                                        "email": "abc@example.com",
                                        "name": "ABC Org"
                                    },
                                  ]
                              },
                              {
                                  "components": []
                              },
                              {
                                  "components": []
                              },
                              {
                                  "components": []
                              }
                          ]
                      ]
                  }
              ]
          }
      ]
  }).then((form) => {
  })
  }

}
