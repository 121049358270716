import { NgModule } from "@angular/core";
import { ACCESS_MANAGEMENT_CONFIG, RESOURCE_SERVICE, PersonalProfileComponent, CorporateProfileComponent, AccessManagementPublicRoutingModule } from "@linkdev/access-manage-library";
import { LookupsRoutes } from "@linkdev/lookup-library";
import { GoogleMapsComponent } from "@linkdev/portals-shared";
import { ServiceManagementResourceService, MyTasksComponent, MyRequestsComponent, ServiceManagementRoutes } from "@linkdev/service-management-library";
import { DynamicProfileComponentsDictionary, DynamicProfileMenuComponentsDictionary } from "@linkdev/shared-library";
import { AppConfigService } from "../core/config/app-config.service";
import { Constants } from "../data/constants/constants";
import { ProfileComponentsDictionary } from "./providers/dynamic-profile-components-dictonary";
import { ProfileMenuComponentsDictionary } from "./providers/dynamic-profile-menu-components-dictonary";

@NgModule({
  declarations: [],
  imports: [
    AccessManagementPublicRoutingModule
  ],
  providers:
    [
      {
        provide: ACCESS_MANAGEMENT_CONFIG, useFactory: () => {
          return {
            baseUrl: AppConfigService.settings.accessManagementUrl,
            portalUrl: AppConfigService.settings.publicPortalUrl,
            changePasswordUrl: AppConfigService.settings.changePasswordUrl,
            accountManagementUrl: AppConfigService.settings.accountManagementUrl,
            textboxMaxLength: Constants.maxLengthConfig.textboxDefaultLength,
            textAreaMaxLength: Constants.maxLengthConfig.textAreaDefaultLength,
            socialMediaAccounts: Constants.socialMediaAccounts,
            breadcrumbs: Constants.breadcrumbs,
            isAdmin: false,
            mapType: GoogleMapsComponent,
            modulesRoutes: {
              serviceManagementRoutes: ServiceManagementRoutes,
              lookupManagementRoutes: LookupsRoutes
            }
          }
        }
      },
      {
        provide: RESOURCE_SERVICE, useClass: ServiceManagementResourceService
      },
      { provide: DynamicProfileComponentsDictionary, useClass: ProfileComponentsDictionary },
      { provide: DynamicProfileMenuComponentsDictionary, useClass: ProfileMenuComponentsDictionary },
    ]
})
export class PortalProfileHelperModule { }
