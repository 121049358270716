import { AbstractDynamicFormIODictionary, FormIOItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { FormioBaseComponent } from '@linkdev/formio-library';

@Injectable()
export class DynamicFormIOComponentsDictionary implements AbstractDynamicFormIODictionary {


    private _dynamicComponents: FormIOItem[] = [
        new FormIOItem(FormioBaseComponent, "FormIOBaseComponent"),
    ];
    public getDynamicComponents(): FormIOItem[] {
        return this._dynamicComponents;
    }
}
