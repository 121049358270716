import { DynamicPaymentMethodComponentsDictionary, PaymentMethodComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { CashPaymentComponent, WireTransferPaymentComponent, OnlinePaymentComponent } from '@linkdev/service-management-library';

@Injectable()
export class DynamicPaymentMethodsDictionary implements DynamicPaymentMethodComponentsDictionary {
  public getDynamicComponents(): PaymentMethodComponentItem[] {
    return this._dynamicComponents;
  }

  private _dynamicComponents: PaymentMethodComponentItem[] = [
    new PaymentMethodComponentItem(CashPaymentComponent, "CashPayment"),
    new PaymentMethodComponentItem(WireTransferPaymentComponent, "WireTransferPayment"),
    new PaymentMethodComponentItem(OnlinePaymentComponent, "OnlinePayment"),
  ]
}
