import { AbstractDynamicComponentsDictionary, ComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { RequestPriorityComponent, RequestParticipantComponent, RequesterInfoComponent, RequestAttachmentsComponent, RequestFeesComponent, PaidReceiptsComponent, SubRequestsListComponent, ParentRequestComponent } from '@linkdev/service-management-library';
import { BirthCertificateBasicDataComponent, BirthCertificateBirthLocationComponent, ServiceRequestBodyComponent, CaseManagementComponent, ViolationRequestComponent, BuildingPermitComponent, BuildingPermitInspectionComponent } from '@linkdev/request-sample-library';
import { FoodImporterLicenseRequestComponent, GraduationCertificateRequestComponent, IndustrialRecordRequestComponent, LabAccreditationRequestComponent, LabAccreditationRequestPolicyComponent, LicenseRequestServiceComponent, SoleProprietorshipsRequestComponent } from '@linkdev/mota-service-library';
import { AddEditInspectionModulesIntegrationComponent } from '@linkdev/inspection-library';
import { AppointmentsModulesIntegrationInstanceComponent } from '@linkdev/appointment-library';
import { MyRequestTasksWrapperComponent } from '@linkdev/portals-shared';

@Injectable()
export class DynamicComponentsDictionary implements AbstractDynamicComponentsDictionary {
    public getDynamicComponents(): ComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: ComponentItem[] = [
        new ComponentItem(RequesterInfoComponent, "RequesterInfo"),
        new ComponentItem(ServiceRequestBodyComponent, "RequestForm"),
        new ComponentItem(RequestAttachmentsComponent, "RequestAttachments"),
        new ComponentItem(RequestFeesComponent, "RequestFees"),
        new ComponentItem(PaidReceiptsComponent, "PaidReceipts"),
        new ComponentItem(RequestParticipantComponent, "RequestParticipant"),
        new ComponentItem(RequestPriorityComponent, "RequestPriority"),
        new ComponentItem(CaseManagementComponent, "CaseManagement"),
        new ComponentItem(ViolationRequestComponent, "ViolationRequestComponent"),
        new ComponentItem(BuildingPermitComponent, "BuildingPermitComponent"),
        new ComponentItem(BuildingPermitInspectionComponent, "BuildingPermitInspectionComponent"),

        new ComponentItem(SubRequestsListComponent, "SubRequestsListComponent"),
        new ComponentItem(ParentRequestComponent, "ParentRequestComponent"),

        new ComponentItem(BirthCertificateBirthLocationComponent, "BirthCertificateRequestBirthLocation"),
        new ComponentItem(BirthCertificateBasicDataComponent, "BirthCertificateRequestBasicData"),
        new ComponentItem(LicenseRequestServiceComponent, "RequestLicenseForm"),
        new ComponentItem(IndustrialRecordRequestComponent, "IndustrialRecordRequestForm"),
        new ComponentItem(LabAccreditationRequestComponent, "LabAccreditationRequestForm"),
        new ComponentItem(LabAccreditationRequestPolicyComponent, "LabAccreditationRequestPolicies"),
        new ComponentItem(SoleProprietorshipsRequestComponent, "SoleProprietorshipsRequestForm"),
        new ComponentItem(GraduationCertificateRequestComponent, "GraduationCertificateRequestForm"),
        new ComponentItem(FoodImporterLicenseRequestComponent, "FoodImporterLicenseRequestForm"),

        new ComponentItem(AppointmentsModulesIntegrationInstanceComponent, "AppointmentsModulesIntegrationInstance"),
        new ComponentItem(AddEditInspectionModulesIntegrationComponent, "PlanNewInspectionForm"),
    
        new ComponentItem(MyRequestTasksWrapperComponent, "MyRequestTasksWrapperComponent"),
    ]
}
