import { Component, OnInit } from '@angular/core';
//import { UserService } from 'src/app/core/services/user.service';
import { Helper, UserService } from '@linkdev/shared-library';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.css']
})
export class LogoutButtonComponent implements OnInit {

  constructor(private _userService: UserService) { }

  ngOnInit(): void {
  }

  onClick() {
    localStorage.removeItem("onBehafeOf");
    localStorage.removeItem("mainAccount");
    localStorage.removeItem("currentIndex");
    
    this._userService.logout().subscribe(_ => { });
  }

}
