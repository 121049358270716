import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTemplateComponent } from './form-template.component';
import { FormsModule } from '@angular/forms';
import { FormControlModule, CoreModule, TableGridModule } from '@linkdev/shared-library';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HeaderFooterModule } from '../header-footer/header-footer.module';




@NgModule({
  declarations: [FormTemplateComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    FormControlModule,
    BsDropdownModule.forRoot(),
    HeaderFooterModule
  ],
  exports: [
    FormTemplateComponent
  ]
})
export class FormTemplateModule { }
