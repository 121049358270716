import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { Helper } from '@linkdev/shared-library';
import { TranslateService } from '@ngx-translate/core';
import { DimUrl } from './header-footer/header/DimUrls';
import { AppConfigService } from './core/config/app-config.service';


const styleSheets = {
  portalRtl: '/assets/css/portal-rtl.css',
  portalLtr: '/assets/css/portal-ltr.css',
  portalContrastRtl: '/assets/css/portal-contrast-rtl.css',
  portalContrastLtr: '/assets/css/portal-contrast-ltr.css',
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  dimHeader = false;
  isContrast: Boolean =
    window.localStorage.getItem('color-theme') === 'contrast-theme';

  isRtl: Boolean;
  mainCssFile: any;
  switchedCssFile: any;
  chatbotEnabled: boolean;

  constructor(private translate: TranslateService, private router: Router) { }

  ngOnInit(): void {

    this.chatbotEnabled = AppConfigService.settings.chatbotEnabled == null ? false :
      typeof (AppConfigService.settings.chatbotEnabled) == 'boolean' ? AppConfigService.settings.chatbotEnabled :
        AppConfigService.settings.chatbotEnabled === "true";


    // if (environment.production) {
    //   if (location.protocol === 'http:') {
    //     window.location.href = location.href.replace('http', 'https');
    //   }
    // }
    Helper.checkIsMobile();

    this.translate.reloadLang(this.translate.currentLang).subscribe(resourceObj => {
      let resource = resourceObj.languageSwitcher;
      this.loadAssets(resource.lang, resource.dir);
    });

    this.router.events.subscribe((e) => {

      //Check after every navigation the change of language
      if (e instanceof NavigationEnd) {
        this.dimHeader = DimUrl.some((ulr) => e.url.indexOf(ulr) > 0);
        // DimUrl.forEach(ulr => {
        //   this.dimHeader = false;
        //   if (e.url.indexOf(ulr) > 0) {
        //     this.dimHeader = true;
        //   }
        // });
        let langChanged = Helper.getCookieValue("lang") != this.translate.currentLang;
        if (langChanged) location.reload();

      }
      if (e instanceof NavigationStart) {
        document.title = "ServeBig"
      }
    });


    this.router.errorHandler = (error: any) => {
      if (error?.message?.indexOf("Cannot match any routes. URL Segment:") != -1) {
        this.router.navigate(['error'], { queryParams: { message: "pageNotFound" } });
      }      
      else{
        console.error(error);
      }
    }
  }

  loadCssFile() {
    this.mainCssFile.onload = () => {
      document.getElementById("preloader").classList.add("hide");
    }
    if (this.isContrast) {
      this.mainCssFile.setAttribute(
        'href',
        this.isRtl
          ? styleSheets.portalContrastRtl
          : styleSheets.portalContrastLtr
      );
      document.querySelector('html').classList.add('contrast');
    } else {
      this.mainCssFile.setAttribute(
        'href',
        this.isRtl ? styleSheets.portalRtl : styleSheets.portalLtr
      );
    }
  }

  createLinkTag(id) {
    var head = document.head;
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = id;
    head.appendChild(link);
    this[id] = document.getElementById(id);
  }

  loadAssets(lang, dir) {
    this.createLinkTag('mainCssFile');
    this.isRtl = dir == 'rtl';
    document.dir = dir;
    document.documentElement.lang = lang;
    this.loadCssFile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    Helper.checkIsMobile();
  }
}
