import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-switch-profile',
  templateUrl: './switch-profile.component.html'
})
export class SwitchProfileComponent implements OnInit {
 @Input() teams: any;
 @Input() linkedProfiles: any;
  translation: any;
  tableHeader: { field: string; header: any; dataType: string; editable: boolean; alternateField: string; }[];
  

  constructor(
     public translateService: TranslateService) {
    this.translateService.get("accessManagement").subscribe((trans) => {
      this.translation = trans;
      document.title = this.translation ?.relatedProfiles || "Related Profiles";
    });
    this.tableHeader = [
      {
        field: this.translateService.currentLang == 'ar' ? 'fullArabicName' : 'fullEnglishName',
        header: this.translation ?.fullName || 'Name',
        dataType: "string", editable: false,
        alternateField: this.translateService.currentLang == 'ar' ? 'fullArabicName' : 'fullEnglishName'
      },
      { field: 'email', header: this.translation ?.emailAddress || 'Email', dataType: "string", editable: false, alternateField: 'email' },
      { field: 'profileTypeName', header: this.translation ?.type || 'profile Type', dataType: "string", editable: false, alternateField: 'profileTypeName' },
      { field: 'requestStatus', header: this.translation ?.requestStatus || 'Status', dataType: "string", editable: false, alternateField: 'requestStatus' },
    ];
   }

  ngOnInit(): void {
  }
 
}
