import { Injectable } from "@angular/core";
import { DynamicViewRequesterInfoComponentsDictionary, ViewRequesterInfoComponentItem } from '@linkdev/shared-library';
import { RequesterInfoPersonalComponent, RequesterInfoCorporateComponent } from '@linkdev/service-management-library';

@Injectable()
export class DynamicViewRequesterInfoDictionary implements DynamicViewRequesterInfoComponentsDictionary {
    public getDynamicComponents(): ViewRequesterInfoComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: ViewRequesterInfoComponentItem[] = [
        new ViewRequesterInfoComponentItem(RequesterInfoPersonalComponent, "RequesterInfoPersonalComponent"),
        new ViewRequesterInfoComponentItem(RequesterInfoCorporateComponent, "RequesterInfoCorporateComponent"),
    ]

}