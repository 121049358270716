<div class="top-mobile-ribbon d-lg-none mb-0">
  <div class="container">
    <div class="ribbon-wrp">
      <div class="navigation-wrp">
        <a class="back-link" [routerLink]="profileMenuLink" [routerLinkActive]="['active']">
          <span class="lib-icon-left-arrow"></span>
        </a>
        <h1 class="page-title"> {{'header.settings' | translate}}</h1>
      </div>
    </div>
  </div>
</div>

<div [class]="isMobile ? 'mobile-settings d-lg-none' : 'header-dropdown-menu settings-menu dropdown-menu'">
  <div [class]="isMobile ? 'settings-menu' : ''">
    <ul class="dropdown-list">
      <li class="dropdown-menu-item lang-switcher">
        <app-language-switcher></app-language-switcher>
      </li>
      <li class="dropdown-menu-item font-resize">
        <span class="title">{{ "header.fontSize" | translate }}</span>
        <ul class="options-wrp">
          <li class="font-option large-font" (click)="resizeFont('large-font')"
            [class.active]="fontSelected === 'large-font'">
            <button>A</button>
          </li>
          <li class="font-option medium-font" (click)="resizeFont('medium-font')"
            [class.active]="fontSelected === 'medium-font'">
            <button>A</button>
          </li>
          <li class="font-option small-font" (click)="resizeFont('small-font')"
            [class.active]="fontSelected === 'small-font'">
            <button>A</button>
          </li>
        </ul>
      </li>
      <li class="dropdown-menu-item contrast">
        <span class="title">{{ "header.contrast" | translate }}</span>
        <div class="options-wrp">
          <span>{{'header.highContract' | translate}}</span>
          <label class="radio-toggler contrast-toggler" (click)="switchThemes($event)">
            <input id="customSwitch1" type="checkbox" [class.checked]="isContrast" />
            <span class="circle"></span>
            <span class="switch-bg"></span>
          </label>
        </div>
      </li>
      <ng-container *ngIf="currentUserName && !isMobile">
        <li class="dropdown-menu-item account">
          <span class="title">{{ "header.account" | translate }}</span>
          <div class="options-wrp">
            <app-logout-button></app-logout-button>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>