import { Component, OnInit } from '@angular/core';
import { ForceRefreshPageService } from '@linkdev/shared-library';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {

  constructor(public translate: TranslateService,
    private _forceRefreshPage: ForceRefreshPageService) { }

  ngOnInit(): void {
  }

  onLanguageSwitched(event, lang) {
    document.getElementById('preloader').classList.remove('hide');
    this.translate.use(lang).subscribe(() => {
      this._forceRefreshPage.ForceRefreshPageService(true);
      location.reload();
    });
    event.preventDefault();
  }

  public getCurrentLang(): string {
    return this.translate.currentLang;
  }

}
