import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Helper } from '@linkdev/shared-library';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
})
export class CookieConsentComponent implements OnInit {
  showPopup: boolean = true;

  constructor() {}

  @Input() config = {
    title: 'Cookies',
    messageText:
      'This site uses cookies to offer you a bettter browsing your experience',
    buttonText: 'Allow Cookies',
  };

  ngOnInit(): void {
    let cookieConsented = Helper.getCookieValue('cookieConsented');
    if (cookieConsented) {
      this.showPopup = false;
    }
  }

  public onAllowClick() {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 12);
    document.cookie = `cookieConsented=true; expires=${expiryDate.toUTCString()};`;
    this.showPopup = false;
  }
}
