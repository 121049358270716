<div class="container bg-white">
  <div class="row">
    <div class="form-section w-100">
      <div class="section-title-wrp">
        <h2>profile type</h2>
      </div>

      <app-alert-message class="my-2" [hasClose]="false" alertType="message" [active]="true"
        alertText="A simple message alert—check it out!">
      </app-alert-message>
      <div class="container inspection">
        <!-- <table class="table">

          <tbody>
            <tr>
              <td *ngFor="let role of roles; let i= index"> 
                <p class="subTitle">{{role?.displayName}} </p>
                <label>{{role?.employesName}} </label></td>
              <td >
                <app-check-input
                *ngIf="role.sections && role.sections?.length"
                [name]="'roleSections'+i"
                [attr.id]="'roleSections'+ i"
                label=""
                [(ngModel)]="role.selectedPermissions"
                valueProperty="id" displayProperty="displayName"
                [checkInputList]="role.sections"
                type='checkbox' 
                [isSelectAll]="true"
                (onChange)="onChangePermission($event, role.selectedPermissions)">
              </app-check-input>
              </td>
             </tr>
        

          </tbody>
        </table> -->
        <div class="d-flex justify-content-between assign-header"  >
          <div  *ngFor="let role of getRoles(); let i= index">
            <div class="form-group pt-3 px-3 mb-n1">
            <label class="readonly-label truncate truncate ">{{role?.roles}} </label>
            <p class="readonly truncate ">{{role?.displayName}} </p>
          </div>
            <div class="group-right-access assign-body">
                                 <app-check-input
                *ngIf="role.sections && role.sections?.length"
                [name]="'role'+i"
                [attr.id]="'role'+ i"
                label=""
                [(ngModel)]="role.selectedSections"
                valueProperty="id" displayProperty="displayName"
                [checkInputList]="role.sections"
                type='checkbox' 
                [isSelectAll]="true"
                (onChange)="onChangePermission($event, role)">
              </app-check-input>

              </div>

        </div>
      </div>

      </div>
     <div class="container inspection">
        <ul class="d-flex justify-content-between flex-wrap align-items-center">
          <li class="col-12 col-md-4 mb-3" *ngFor="let role of itemsRoleWithEmployes">
            <div class="">
            <p>{{role?.role}} <span class="subTitle">(assign 2 min)</span></p>
            <ul class="">
            <li  *ngFor="let item of role.employesName" class="d-flex justify-content-between  align-items-center rounded mb-1 px-3 pt-1 pb-1" style="background-color: #fbfbfb;">
              <span title="{{item}}" class="titleText text-truncate">{{item}}</span>
              <button  (click)="deleteItem(item)" class="btn-link" >  <span class="lib-icon-close align-middle"></span>
              </button>
            </li>
            </ul> 
          </div>
         </li> 
        </ul>
      </div>
      <div class="container inspection">
        <app-select-box [showSelectOption]="false" [noLabel]="true"
        (onChange)="onLanguageChange1($event)" [(ngModel)]="currentCultureId" displayProperty="displayName"
        valueProperty="id" [hasClear]="false" name="language" [customStyleClass]="'w-outline-primary-btn'"
        [items]="cities" showCustomContent="true"
        >
    </app-select-box>
    <div class="form-row">
      <div class="col-md-6 col-lg-4 form-group lib-select">
        <app-select-box  label="select box" [appearAsterisk]="true" class="" [items]="itemsTest" 
      (onChange)="onLanguageChange2($event)" [(ngModel)]="currentIdTest" displayProperty="displayName"
      valueProperty="id" [hasClear]="false" name="f" ></app-select-box>
        <app-validation [condition]="false" validationMessage="required"></app-validation>
      </div>
    </div>
    <!-- <p-dropdown  (onChange)="onChangeFn($event)" 
    id="season" placeholder="select"
    [(ngModel)]="innerValue"
    [options]="cities"
    optionLabel="name">

    <ng-template let-item pTemplate="item">
      <div class="drop-item"
      [ngClass]="item.value.added ? 'add-action' : ''">
      <span class="drop-item-value">
        {{item.value.name}}
        </span>
        </div>
    </ng-template>

    <ng-template let-item pTemplate="selectedItem">
        {{item.value.name}}
    </ng-template>
</p-dropdown> -->

        <!-- <label [ngClass]="{ 'readonly-label': false }">List 1 (NO)
          <span class="required" *ngIf="true">*</span></label>
        <ng-template [ngIf]="true">
          <p-dropdown [(ngModel)]="innerValue" 
         [options]="cities" [optionLabel]="''" 
            styleClass="form-control"
        [placeholder]="'placeholder'" >
            <ng-template let-elem pTemplate="item" *ngIf="true">
              <div class="drop-item"
                [ngClass]="elem.added ? 'add-action' : ''">
                <span class="drop-item-value">
                  {{elem.name}}
                </span>
              </div>
          </ng-template> 
          </p-dropdown>
        </ng-template> -->

      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <app-file-upload [mode]="2" label="file upload" validationText="required"></app-file-upload>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-lg-4 form-group mb-3">
          <app-text-box label="text box " [isReadOnly]="true" [appearAsterisk]="true" type="text" placeholder="text box" [showClear]="true">
          </app-text-box>
          <app-validation [condition]="true" validationMessage="required"></app-validation>
          <app-validation [condition]="true" validationMessage="required"></app-validation>
        </div>
        <div class="col-md-6 col-lg-4 form-group">
          <app-text-box label="text box fghfj" [appearAsterisk]="false" type="tel" placeholder="text box" [showClear]="true"></app-text-box>
          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
        <div class="col-md-6 col-lg-4 form-group mb-3">
          <app-gregorian-date-picker [validationCondition]="false" [id]="'dateOfBirthId'" label="Date Of Birth"
            [appearAsterisk]="true" required name="dateOfBirth" #dateOfBirth="ngModel" ngModel>
          </app-gregorian-date-picker>
          <!-- <app-validation [condition]="isSubmited && dateOfBirth.errors?.required"
              [validationMessage]="translation?.requiredField || 'This Field is required'">
          </app-validation> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-lg-4 form-group">
          <app-gregorian-date-picker label="date picker" [appearAsterisk]="true"></app-gregorian-date-picker>
          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
        <div class="form-group col-md-6 col-lg-4 form-group">
          <app-file-upload [appearAsterisk]="true" [mode]="1" label="file upload" validationText="required">
          </app-file-upload>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-lg-4 form-group lib-select">
          <multi-select [hasClear]="false" name="scientificOrganization"
          label="{{translation?.scientificOrganization || 'Scientific Organization'}} "   #scientificOrganization="ngModel"
          [placeholder]="'common.select' | translate" [showSelectedItemsTags]="true"
          [isReadOnly]="true" [multiple]="false"
          [validationCondition]="scientificOrganization.errors?.required"
          [(ngModel)]="scientificOrganizationId" displayProperty="displayName" valueProperty="id"
          [items]="itemsTest"
          >
      </multi-select>          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-lg-4 form-group lib-select">
          <app-select-box label="select box" [appearAsterisk]="true" class="" [items]="itemsTest" 
        (onChange)="onLanguageChange($event)" [(ngModel)]="innerValue" displayProperty="displayName"
        valueProperty="id" [hasClear]="false" name="f" ></app-select-box>
          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
        <div class="col-md-6 col-lg-4 form-group lib-select">
          <app-select-box label="select box" [appearAsterisk]="false" class="" [items]="cities"></app-select-box>
          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-lg-4 form-group">
          <app-text-box label="text box" [appearAsterisk]="false" type="mail" placeholder="text box"></app-text-box>
          <app-validation [condition]="false" validationMessage="required"></app-validation>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-lg-4 form-group">
          <app-check-input type="checkbox" displayProperty="name" label="check boxes" [appearAsterisk]="true"
            [checkInputList]="checkInputList">
          </app-check-input>
        </div>
        <div class="col-md-6 col-lg-4 form-group">
          <app-check-input type="radio" label="radio buttons" displayProperty="name" name="options"
            [checkInputList]="checkInputList">
          </app-check-input>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 form-group">
          <app-text-area label="text area" placeholder="text area" maxlength="500"></app-text-area>
        </div>
      </div>
    </div>
    <div class="form-section w-100">
      <div class="section-title-wrp">
        <h2>profile type</h2>
      </div>
      <app-alert-message class="my-2" alertType="success" [active]="true"
        alertText="A simple success alert—check it out!">
      </app-alert-message>
      <app-alert-message class="my-2" alertType="danger" [active]="false"
        alertText="A simple danger alert—check it out!">
      </app-alert-message>
      <app-alert-message class="my-2" alertType="info" [active]="true" alertText="A simple info alert—check it out!">
      </app-alert-message>
      <app-alert-message class="my-2" alertType="warning" [active]="true"
        alertText="A simple warning alert—check it out!">
      </app-alert-message>
      <!-- <app-table-grid
        [tableHeader]="tableHead"
        showRows="3"
        [hasPager]="false"
        gridTitle="People"
        [tableContent]="tableContent"
        modalId="fillProfile"
        [editMode]="true"
        deleteMode="true"
      ></app-table-grid> -->
    </div>
    <div class="form-section w-100">
      <div class="row">
        <div class="col-12">
          <div class="section-title-wrp">
            <h2 class="text-capitalize">Contact Details</h2>
          </div>
          <app-no-data icon="call" modalId="phoneNumber" addDataLink="add Phone Number" noDataMessage="Phone Number">
          </app-no-data>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <lib-pager (pageChange)="pageChange($event)" showRows="10" totalRecordsShow="120"></lib-pager>
</div>

<div class="container bg-white">
  <div class="row">
    <button type="button" class="btn btn-primary">save</button>
    <button type="button" class="btn btn-secondary">delete</button>
    <button type="button" class="btn btn-outline-primary">save</button>
    <button type="button" class="btn btn-outline-secondary">delete</button>
    <button type="button" class="btn disabled">disabled</button>
    <button type="button" class="btn btn-secondary disabled">
      secondary disabled
    </button>
  </div>
</div>


<lib-messages  [mode]="1" messageTitle="Successfully Sent!" optionalMessage="Request has been successfully sent">
<ng-template #content>
    <div class="lower-section">
      <div class="request-id">Request Id: <a href="">23646875465778</a></div>
      <button type="button" class="btn btn-primary mx-auto d-block">Go to workspace </button>
    </div>
</ng-template>
</lib-messages>



<button type="button" class="btn btn-primary" (click)="openModal(auditTrail)">Create template modal</button>
<ng-template #template3>
  <lib-modal title="test modal 3" (onSubmit)="save()" (onCancel)="onCancel()" primaryAction="three"
    secondaryAction="cancel" [modalContent]="modalContent3">
    <ng-template #modalContent3>
      modal three
    </ng-template>
  </lib-modal>
</ng-template>

<app-text-editor></app-text-editor>

<button type="button" class="btn btn-primary" (click)="openConfirmModal('addTransModalRef')">Create confirm modal</button>


<ng-template #addTransModalRef>
  <lib-confirm-modal [customFooter]="true" title="Add Translation" icon="lib-icon-check"
      [modalContent]="modalContent1"
      [footerContent]="footerContent1"
      (onCancel)="onCancel()">
      <ng-template #modalContent1>
          <div class="modal-note">
              You are about to cancel this request
          </div>
          <div class="request-id"><div><span class="label">No.:</span><span class="text-primary ng-star-inserted">Sample1-2020-12-7-528</span></div></div>
      </ng-template>
      <ng-template #footerContent1>
        <button (click)="onCancel()" type="button" class="btn btn-outline-primary">continue</button>
        <div dropdown [insideClick]="true" class="dropdown lib-dropdown">
          <button class="btn btn-primary" dropdownToggle placement="bottom">
            <span class="lib-icon-add"></span>
            Add Translation
          </button>
          <div class="dropdown-menu" *dropdownMenu>
            <ul>
              <li><a href="" class="dropdown-item">Arabic</a></li>
              <li><a href="" class="dropdown-item">english</a></li>
              <li><a href="" class="dropdown-item">french</a></li>
            </ul>
          </div>
        </div>
    </ng-template>
  </lib-confirm-modal>
</ng-template>

<!-- <app-table-grid></app-table-grid> -->


<div class="top-mobile-ribbon fixed mb-0">
  <div class="container">
    <div class="ribbon-wrp">
      <div class="navigation-wrp">
          <h1 class="page-title">{{ translation?.workspace || "Workspace" }}</h1>
      </div>
          <div class="ribbon-options">
            <div class="ribbon-option search">
              <button class="search-btn" (click)="toggleSearch()" [class.open]="isSearchOpen">
                <span class="icon-search"></span>
              </button>
              <div class="search-mini-slide" [class.active]="isSearchOpen">
                  <div class="position-relative">
                      <form (ngSubmit)="onFilter()" #filterMyRequests='ngForm' name="filterMyRequests">
                          <app-text-box id="keywordSearchMobilereq" name="keyword" placeholder="{{translation?.searchByKeyword || 'Search By Keyword' }}"
                                [(ngModel)]="searchCriteria.keyword" #keyword="ngModel" type="text" [maxLength]="50" [tabindex]="0" [showClear]="true"
                                class="w-100"
                                (onChange)="addKeywordSearch($event)">
                            </app-text-box>
                            <button type="submit">
                                <span class="icon-search"></span>
                            </button>
                      </form>
                  </div>
              </div>
            </div>


          </div>
    </div>
  </div>
</div>



<div class="d-flex align-items-center">

  <form (ngSubmit)="onFilter()" #filterMyRequests='ngForm' name="filterMyRequests" class="w-100 d-flex">
        <div class="flex-grow-1">


          <div class="workspace">
              <!---------------------------- Drafts ---------------------------->
              <div class="section-content">
                      <!-- <div id="collapseDrafts" [ngClass]="{'collapse': myRequests?.drafts?.length}"> -->
                          <app-table-grid
                          id="draftsRequests"
                          *ngIf="myRequests?.drafts?.length; else noData"
                          [hasSearch]="false"
                          [lazyPager]="true"
                          [totalRecords]="totalDraftsCount"
                          [hasAddButton]="false"
                          [tableHeader]="draftsTableHeader"
                          [tableContent]="myRequests?.drafts"
                          [showRows]="3"
                          [hasPager]="true"
                          [startPage]="startPage"
                          gridTitle="{{ translation?.drafts || 'Drafts' }}"
                          deleteConfirmationTitle="{{ translation?.deleteRequestConfirmationTitle || 'Delete Request' }}"
                          deleteConfirmationSubTitle="{{ translation?.deleteConfirmation || 'Are you sure?' }}"
                          deleteConfirmationMessage="{{ translation?.deleteRequestConfirmation || 'You are about to delete this Request Details, once deleted you can not undo this action.' }}"
                          modalId="draftsInnerModal"
                          [editMode]="false"
                          [deleteMode]="false"
                          [hideTitle]="false"
                      
                          [keyField]="'ID'"
                          [EnableLinkClickOnDelete]="false"
                          (onConfirmDeleteClick)="onConfirmDeleteClick($event)"
                          (gridCellUpdatedInLine)="updateCorrespondingProp($event)"
                          (onSortOrPagerClick)="onSortOrPagerClick($event, true)"
                         
                          (onChangeFn)="changeFn($event)"
                          ></app-table-grid>
                        <!-- </div> -->
                  </div>

                  <ng-template #noData>
                  <app-no-data
                      icon="empty-documents"
                      noDataTitle="{{ translation?.drafts || 'Drafts'  }}"
                      noDataMessage="{{
                      translation?.noDraftsMessage || 'No Drafts found'
                      }}"
                  ></app-no-data>
                  </ng-template>
              </div>

      </div>


  </form>
</div>

<app-btn-toggle [(ngModel)]="toggle"></app-btn-toggle>
<button (click)="getV()">ads</button>

<div class="profile-switcher-content">
  <div class="d-flex flex-column">
    <div class="d-flex  align-items-center pb-5" (click)="openTabs()">
      <div *ngIf="!item?.img" class="user-pic">
        <img src="../../assets/images/avatar.png" [alt]="item?.userDisplayName" />
      </div>
      <div *ngIf="item?.img" class="user-pic">
        <img [src]="item?.img" [alt]="item?.userDisplayName" />
      </div>
        <span class="account-name text-truncate  font-weight-bold">{{item?.userDisplayName}}</span>
      <span class="lib-icon-down-head-arrow custom"  [ngClass]="{'arrow-up': isOpenTabs}"></span>
    </div>
    <a class="link-btn pt-3 pb-3 divider"
    href="#">Set as Default <span class="icon-flat-arrow-right"></span></a>
    <a class=" link-btn pt-3 divider"
    href="#">No Related Profile Selected  <span class="icon-flat-arrow-right"></span></a>
  </div>
</div>
<ng-container *ngIf="isOpenTabs">
<div class="account-tabs">
  <app-related-profile-tabs class="d-block w-100" [teamsOwners]="[{userDisplayName: 'Amel'}, {userDisplayName: 'Amel2'}]"
   (onSelectAccount)="onSelectAccount($event)"
   ></app-related-profile-tabs>
</div>
</ng-container>
<!-- // {{i != componentData?.permissionsCategory.length -1 ? 'mb-2': ''}} -->
<!-- [attr.aria-controls]="item.categoryName" -->
<ng-template #auditTrail>
  <lib-modal title="Audit Trail" (onCancel)="onCancel()" [hasPrimaryAction]="false"
    secondaryAction="cancel" [modalContent]="modalContent3">
    <ng-template #modalContent3>
      <div class="audit-trail ">
        <div class="form-row">
          <div class="form-group col-12 col-md-4">
            <div class="d-flex">
              <div class="user-pic">
                <img [src]="defaultAvatar" [alt]="'userDisplayName'" />
              </div>
              <div class="mx-2 remainPic-width">
                <label class="readonly-label" title="Account Type">Account Type</label>
                <p class="readonly truncate " title="profileTypeLocal">profileTypeLocal</p>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="readonly-label" title="Account Type">Account Type</label>
            <p class="readonly truncate text-truncate" title="profileTypeLocal">profileTypeLocal</p>
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="readonly-label" title="Account Type">Account Type</label>
            <p class="readonly truncate text-truncate" title="profileTypeLocal">profileTypeLocal</p>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group">
            <div class="title truncate" title="Basic Information">Basic Information</div>
          </div>
        </div>
        <div class="form-row audit-trail-details">
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through " title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through px-1" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through px-1" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through px-1" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group">
            <div class="title truncate " title="Basic Information">Steps</div>
          </div>
        </div>
        <div class="form-row mx-2">
          <div class="col-12 form-group">
            <div class="sub-title  " title="Step Initiation 101">Step Initiation 101 Step Initiation 101 Step Initiation 101Step Initiation 101 Step Initiation 101 </div>
          </div>
        </div>
        <div class="form-row audit-trail-details">
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-6 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 col-md-6 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
      
      
      
        </div>
        <div class="border-top mb-3"></div>
        <div class="form-row mx-2">
          <div class="col-12 form-group">
            <div class="sub-title " title="Step Initiation 101">Step Initiation 102 </div>
          </div>
        </div>
        <div class="form-row audit-trail-details">
          <div class="col-12 col-md-4 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
          <div class="col-12 form-group ">
            <label class="readonly-label truncate" title="Basic Information">Account Type</label>
            <p class="readonly-line-throgh old-data text-break line-through" title="Basic Information">profileTypeLocal</p>
            <p class="readonly text-break new-data" title="Basic Information">profileTypeLocal</p>
          </div>
      
      
      
        </div>
      </div>
    </ng-template>
  </lib-modal>
</ng-template>

  <div class="form-group">
    <!-- <input-auto-complete [emittedItemsList]="peopleSearchItems"
        [emitItems]="true"
        (emitttedItems)="setPeopleSearchItems($event)" [id]="'modifiedByreq'"
        [(ngModel)]="modifiedBy" name="RedirectToUser"
        label="{{'Last Modified By' }}"
        (onChange)="addFilterCriteriat('ModifiedBy', 'Like', $event?.emailAddress)"
        [emptyMessage]="'No Result Found'"
        [placeholder]="'Select'"
        [pickerServiceUrl]="otheradminsServiceUrl" [items]="otherAdminsList"
        [valueProperty]="'emailAddress'" [displayProperty]="'displayName'">
    </input-auto-complete> -->
    <input-auto-complete

                                    label="{{ 'Redirect task to another user' }}"
                                    [(ngModel)]="modifiedBy" required #redirectUser name="redirectUser"
                                    (onChange)="addFilterCriteriat('ModifiedBy', 'Like', $event?.emailAddress)"
                                    [emptyMessage]="'No Result Found'"

                                    [placeholder]=" 'Select'"
                                    [pickerServiceUrl]="otheradminsServiceUrl"

                                    [items]="otherAdminsList"
                                    [valueProperty]="'emailAddress'" [displayProperty]="'displayName'">

                                </input-auto-complete>
    {{modifiedBy}}
</div>
<lib-dropdown-action rowData="{name: 'AMEL', 'id': '1'}" (onDropdownOpenHandle)="onDropdownOpenHandle($event)" [dropdownItems]="dropdownItems"></lib-dropdown-action>


<div class="form-row">
  <div class="col-12 form-group">
      <app-check-input  type="radio"
          label="{{ 'Allow Multiple Assignment to Same Level' }}"
          #test="ngModel"
          [(ngModel)]="test1"  valueProperty="id"
           displayProperty="displayName"  name="test11"
          [checkInputList]="itemsTest"
          (onChange)="onTest1Change($event)">
      </app-check-input>
  </div>
  <div class="col-12  form-group">
    <app-check-input type="radio" 
        label="{{ 'Allow Multiple Assignment to Same Level2' }}"
        #multipleAssignment="ngModel"
        [(ngModel)]="test2"  valueProperty="id"
         displayProperty="displayName"  name="multipleAssignment"
        [checkInputList]="itemsTest2">
    </app-check-input>
</div>
<button (click)="tedetect()">testsds saf</button>

<app-slider-numeric-input id="score" min="0" max="10"
                  [(ngModel)]="testValuel" name="score_" 
                  (onChange)="updateOriginalQuestionScore($event)"
                  label="Score out of 10"></app-slider-numeric-input>
</div>

<!--         [validationCondition]="isSubmitted && inspectionObjectiveDescription.errors?.required"
 -->
   <!--#region Inspection Objectives Description-->
   <div class="form-row">
    <div class="col-12 form-group">
  <app-text-editor 
        name="inspectionObjectiveDescription" 
        [id]="'inspectionObjectiveDescription'"
        [required]="true"
        [appearAsterisk]="!isReadOnly"
        [(ngModel)]="inspectionDescription"
        #inspectionObjectiveDescription="ngModel" [isReadOnly]="isReadOnly"
        label="{{translation?.campaignObjectiveDescription ||'Campaign Description'}}"
        [showTooltip]="false"
        [textTooltip]=""
        [isTextNeedEditor]="!isReadOnly">
  </app-text-editor>
      <app-validation [condition]="inspectionObjectiveDescription?.errors?.required"
        [validationMessage]="translation?.campaignObjectiveDescriptionRequired ||'This Field is required'">
      </app-validation>
      {{inspectionObjectiveDescription?.errors?.required}}
    </div>
  </div>