import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, Input, ViewChild, HostListener } from '@angular/core';
import { PublicProfileService, ProfileLinkService, ImpersonationService, ProfileStatusEnum } from '@linkdev/access-manage-library';
import { Router } from '@angular/router';
import { User } from 'oidc-client';
import { ResponseStatus } from '@linkdev/portals-shared';
import { Helper, UserService, CONSTANTS, ForceRefreshPageService, LookupsService } from '@linkdev/shared-library';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsService } from '@linkdev/document-manage-library';
import { ServiceManagementRoutes } from '@linkdev/service-management-library';
import { AccessManagementRoutes } from '@linkdev/access-manage-library';
import { SignalRService } from '@linkdev/notification-management-library';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  host: {
    '(document:click)': 'onClickOutside($event)',
  },
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() IsDimmed: boolean = false;
  currentUserName: string;
  isMobileMenuOpen: Boolean = false;
  isSearchOpen: Boolean = false;
  fontSelected: string = 'medium-font';
  isContrast: Boolean =
    window.localStorage.getItem('color-theme') === 'contrast-theme'
      ? true
      : false;
  @Output() switchThemes = new EventEmitter();
  currentUser: User;
  userSummaryData: any = {};
  userDisplayName: string;
  hasLinkedOrTeams: boolean = false;
  forceRefreshProfileSummarySubscription: Subscription;
  switchProfileModalRef: BsModalRef;
  tableHeader: { field: string; header: any; dataType: string; editable: boolean; alternateField: string; className?: string; }[];
  translation: any;
  profilesDataSource: any;
  profilesDataSources: any = [];
  currentIndex: number = 0;
  onBehafeOf: any;
  mainAccount: any;
  startPage = { page: 0 };
  hasRelatedAccounts: boolean = true;
  profileAccounts: {}[];
  isRelatedProfileOpen: any;
  keyField = 'userDisplayName';
  currentProfile: any;
  forceOpenImpersonationPopup: Subscription;
  shouldRefreshAfterImpersonation: boolean = false;
  isSelectedUserChanged: boolean = false;

  deactivetedProfileSubscription: Subscription;
  changeProfileSubscription: Subscription;

  defaultAvatar = "../../assets/images/avatar.png";
  notificationsCount = 0;
  notificationMessages = [];
  signalRSubscription: Subscription;
  @ViewChild('relatedProfilesTable') relatedProfilesTable;
  isMobile: boolean;

  profileStatus = ProfileStatusEnum;
  statusList: any;

  isLoading: boolean = true;

  workspaceLink: String = `${ServiceManagementRoutes.baseRoute}/${ServiceManagementRoutes.workspaceRoute}`;

  profileMenuLink: string = `${AccessManagementRoutes.baseRoute}/${AccessManagementRoutes.profileMenuRoute}`;
  profileLink: string = `${AccessManagementRoutes.baseRoute}/${AccessManagementRoutes.profileRoute}`;
  logOutconsumer$: Subscription;

  constructor(
    private _userService: UserService,
    public _signalrService: SignalRService,
    private _profileService: PublicProfileService,
    private router: Router,
    private modalService: BsModalService,
    private _impersonationService: ImpersonationService,
    public translateService: TranslateService,
    public profileLinkService: ProfileLinkService,
    public documentsService: DocumentsService,
    private _forceRefreshPage: ForceRefreshPageService,
    private lookupService: LookupsService) {

    this.translateService.get("accessManagement").subscribe((trans) => {
      this.translation = trans;
      document.title = this.translation?.relatedProfiles || "Related Profiles";
    });

    this.tableHeader = [
      { field: 'id', header: this.translation?.id || 'id', dataType: "string", editable: false, alternateField: 'id', className: 'w-50' },
      { field: 'profileStatusName', header: this.translation?.status || 'Status', dataType: "string", editable: false, alternateField: 'profileStatusName', className: 'w-50' },
      {
        field: 'userDisplayName',
        header: this.translation?.fullName || 'Name',
        dataType: "string", editable: false,
        alternateField: 'userDisplayName'
      },
      { field: 'email', header: this.translation?.emailAddress || 'Email', dataType: "string", editable: false, alternateField: 'email' },
    ];

    this.forceOpenImpersonationPopup = this._impersonationService.forceOpenImpersonationPopup.subscribe(
      (forcePopup) => {
        this.shouldRefreshAfterImpersonation = forcePopup;
        this.openRelatedProfile();
      }
    );

    this.lookupService.getLookup("ProfileStatus").subscribe(res => {
      this.statusList = res;
      this.setProfileStatusNames();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = Helper.checkIsMobile();
    this.getCorrectPage();
  }
  getCorrectPage() {
    if (!this.isMobile && this.router.url == this.profileMenuLink) {
      this.navigateToProfile();
    }
  }

  ngOnInit(): void {
    this.isMobile = Helper.checkIsMobile();
    this.getCorrectPage();
    this.logOutconsumer$ = this._signalrService.getCommand().subscribe((command) => {
      if (command.commandName == 'LogOut') {
        this._userService.logout().subscribe();
      }
    });
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
    this._userService.getCurrentUser(true).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.currentUserName = user.profile.name;
        this.userSummaryData.img = this.currentUser.profile.picture;
        this.isLoading = false;
        this.getImgPath(this.userSummaryData?.img);
        //this.userDisplayName = this.currentUserName;
        this.getProfileSummary(this.currentUser);
      }
      else {
        this.isLoading = false;
        this._userService.removeUserFromStorage().then(() => {
          console.log("user cleared");
        });
      }
    }, () => {
      this.isLoading = false;
    });

    this.deactivetedProfileSubscription = this.profileLinkService.reloadDataAfterDeactivateLink$.subscribe(
      (deactivateLink) => {
        let item = this.profilesDataSources[0].data.find(x => x.id == deactivateLink?.id)
        let index = this.profilesDataSources[0].data.indexOf(item);
        this.profilesDataSources[0].data.splice(index, 1)
        if (localStorage.onBehafeOf) {
          let onBehafeOf = JSON.parse(localStorage.onBehafeOf)
          if (onBehafeOf.id == deactivateLink?.id) {
            localStorage.removeItem("onBehafeOf");
            localStorage.removeItem("mainAccount");
            this.onBehafeOf = null
          }
        }
      }
    );
    this.changeProfileSubscription = this.profileLinkService.changeProfileSwitcher$.subscribe(
      () => {
        if (localStorage.onBehafeOf)
          this.onBehafeOf = JSON.parse(localStorage.onBehafeOf)
        if (localStorage.mainAccount) {
          this.mainAccount = JSON.parse(localStorage.mainAccount);
          this.getImgPath(this.mainAccount?.img);
        }
        if (localStorage.currentIndex)
          this.currentIndex = JSON.parse(localStorage.currentIndex)
      }
    );


    this.forceRefreshProfileSummarySubscription = this._profileService.forceRefreshSummary$.subscribe(
      (forceRefresh) => {
        if (forceRefresh) {
          this.getProfileSummary(this.currentUser);
        }
      }
    );

  }


  navigateToProfile() {

    if (!this.currentUser.profile.providerName || this.currentUser.profile.providerName.toLowerCase() != CONSTANTS.activeDirectoryProviderName.toLowerCase())
      this.router.navigate([this.profileLink]);
  }

  handleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }
  openSearch() {
    this.isSearchOpen = true;
  }

  onClickOutside(e) {
    if (
      e.target.closest('.header-search') !==
      document.querySelector('.header-search')
    ) {
      this.isSearchOpen = false;
    }
  }

  GetCurrentLanguage() {
    return Helper.getCookieValue('lang');
  }

  getProfileSummary(currentUser: User) {
    this._profileService
      .getProfileSummary(currentUser.profile.sub)
      .subscribe((userSummary) => {
        if (userSummary.status == ResponseStatus.Success) {
          let data = userSummary.data;
          this.userSummaryData = data;

          let arabicName =
            !data.fullArabicName || data.fullArabicName.trim().length == 0
              ? null
              : data.fullArabicName;
          this.userSummaryData.img = data.img || currentUser.profile.picture;
          this.getImgPath(this.userSummaryData?.img);
          this.userSummaryData.fullEnglishName = data.fullEnglishName;
          this.userSummaryData.fullArabicName =
            arabicName || data.fullEnglishName;

          this.userSummaryData.userDisplayName = ((this.GetCurrentLanguage() == "en"
            ? this.userSummaryData.fullEnglishName
            : this.userSummaryData.fullArabicName) || this.userSummaryData.fullEnglishName) || this.currentUserName;

          if (this.userSummaryData?.profileStatus == this.profileStatus.Deactivated) {
            this._userService.logout().subscribe();
          }

          this.currentProfile = JSON.parse(JSON.stringify(this.userSummaryData))
          this.getLinkedProfilesAndTeams();
        }

        this.userDisplayName = this.currentUserName;
      },
        () => {
          this.userDisplayName = this.currentUserName;

          // if (err.status == 401) {
          //   this._userService.logout(window.location.href).subscribe();
          // }
        });
  }

  getLinkedProfilesAndTeams() {
    this._profileService
      .getLinkedProfilesAndTeams()
      .subscribe((source) => {

        if (source.status == ResponseStatus.Success) {
          let data = source.data;
          this.userSummaryData.teamsOwners = data?.teamsOwners?.filter(p => p.profileStatus != this.profileStatus.Deactivated);
          data.linkedProfiles = data?.linkedProfiles?.filter(p => p.profileStatus != this.profileStatus.Deactivated)
          if (data?.linkedProfiles) {
            if (!this.userSummaryData?.teamsOwners) {
              this.userSummaryData.teamsOwners = [];
            }

            this.userSummaryData.teamsOwners?.splice(0, 0, this.currentProfile)
            this.profilesDataSources.push({ profileId: this.currentProfile.id, data: data?.linkedProfiles });

          }
          this.userSummaryData.linkedProfiles = data?.linkedProfiles;
          this.userSummaryData.teamsOwners?.forEach(element => {
            let arabicName =
              !element.fullArabicName || element.fullArabicName.trim().length == 0
                ? null
                : element.fullArabicName;
            element.fullArabicName =
              arabicName || element.fullEnglishName;

            element.userDisplayName = (this.GetCurrentLanguage() == "en"
              ? element.fullEnglishName
              : element.fullArabicName) || element.fullEnglishName;
          });
          this.userSummaryData.linkedProfiles?.forEach(element => {
            let arabicName =
              !element.fullArabicName || element.fullArabicName.trim().length == 0
                ? null
                : element.fullArabicName;
            element.fullArabicName =
              arabicName || element.fullEnglishName;

            element.userDisplayName = (this.GetCurrentLanguage() == "en"
              ? element.fullEnglishName
              : element.fullArabicName) || element.fullEnglishName;
          });
          this.userSummaryData.linkedProfiles?.sort(this.compare);

          if (data?.teamsOwners?.length > 0 || data?.linkedProfiles?.length > 0) {
            this.hasLinkedOrTeams = this._impersonationService.currentUserHasLinkedProfilesOrTeams = true;
          }
          if (data.linkedProfiles) {
            this.profilesDataSource = this.userSummaryData.linkedProfiles;
            this.setProfileStatusNames();
            this.currentIndex = 0
          }
          else {
            this.selectDataSource(0, true);
          }
          this.handleSwitchProfile();
        }
      });
  }

  setProfileStatusNames() {
    this.profilesDataSource?.forEach(profile => {
      profile.profileStatusName = this.statusList?.find(s => s.id == profile.profileStatus)?.displayName;
    })
  }

  handleSwitchProfile() {

    if (localStorage.mainAccount) {
      this.mainAccount = JSON.parse(localStorage.mainAccount);
      this.getImgPath(this.mainAccount?.img);
      if (this.mainAccount.id != this.userSummaryData.id &&
        this.userSummaryData.teamsOwners?.findIndex(x => x.id == this.mainAccount.id) == -1) {

        localStorage.removeItem("mainAccount")
        localStorage.removeItem("onBehafeOf")
        localStorage.removeItem("currentIndex")
        this.mainAccount = this.userSummaryData;
        this.getImgPath(this.mainAccount?.img);

        this.handleMainAccount();

        this._forceRefreshPage.ForceRefreshPageService(true);
      }
      else {
        this.handleMainAccount();
        this.handleDataSourceAndIndex();
      }
    }
    else {
      this.handleMainAccount();
      this.handleDataSourceAndIndex();
    }

  }
  handleDataSourceAndIndex() {
    if (!localStorage.currentIndex) {
      var indexOfDefaultProfile = this.userSummaryData.teamsOwners?.findIndex(x => x.id == this.currentProfile.defaultProfileId);
      if (indexOfDefaultProfile < 0) {
        this.currentIndex = 0
        localStorage.currentIndex = 0
      }
      else {
        this.currentIndex = indexOfDefaultProfile
        localStorage.currentIndex = indexOfDefaultProfile;
        this.selectDataSource(this.currentIndex, true);
        return;
      }
    }
    let index = this.userSummaryData.teamsOwners?.findIndex(x => x.id == this.mainAccount.id)
    this.currentIndex = index != -1 ? index : 0
    if (this.currentIndex == 0 && this.userSummaryData?.linkedProfiles) {
      this.profilesDataSource = this.userSummaryData.linkedProfiles;
      this.setProfileStatusNames();
      this.currentIndex = 0
      this.handleOnBehafeOf()

    }
    else {
      this.selectDataSource(this.currentIndex, true);

    }
  }
  handleMainAccount() {
    if (!this.mainAccount) {
      this.mainAccount = this.userSummaryData;
      this.getImgPath(this.mainAccount?.img);
      localStorage.setItem('mainAccount', JSON.stringify(this.mainAccount))
    }
    //because of change language
    else if (this.mainAccount.id == this.userSummaryData.id) {
      this.mainAccount = this.userSummaryData;
      this.getImgPath(this.mainAccount?.img);
      localStorage.setItem('mainAccount', JSON.stringify(this.mainAccount))
    }
  }
  handleOnBehafeOf() {
    if (localStorage.onBehafeOf && localStorage.onBehafeOf != "undefined") {
      let oldValue = this.profilesDataSource?.find(x => x.id == JSON.parse(localStorage.onBehafeOf)?.id);
      if (oldValue) {
        localStorage.setItem('onBehafeOf', JSON.stringify(oldValue));
        this.onBehafeOf = oldValue
      }

      if (this.profilesDataSource.findIndex(x => x.id == this.onBehafeOf?.id) == -1) {
        localStorage.removeItem("onBehafeOf")
        this.onBehafeOf = null;
        this._forceRefreshPage.ForceRefreshPageService(true);
        return;
      }

      this.onBehafeOf.userDisplayName = (this.GetCurrentLanguage() == "en"
        ? this.onBehafeOf?.fullEnglishName
        : this.onBehafeOf?.fullArabicName) || this.onBehafeOf?.fullEnglishName;

    }
  }
  cancelHeaderPopUp() {
    this.switchProfileModalRef.hide();
  }
  openSwitchModal(baseModal) {
    this.switchProfileModalRef = this.modalService.show(baseModal);
  }
  ngOnDestroy(): void {
    this.forceRefreshProfileSummarySubscription.unsubscribe();
    this.logOutconsumer$.unsubscribe();
    // unsubscribe to ensure no memory leaks
    this.signalRSubscription.unsubscribe();
  }
  selectDataSource(index: number, isPageloading = false) {
    if (!isPageloading) {
      if (this.userSummaryData?.teamsOwners) {
        this.mainAccount = this.userSummaryData.teamsOwners[index];
        this.getImgPath(this.mainAccount?.img);
        localStorage.setItem('mainAccount', JSON.stringify(this.mainAccount));
        if (index != this.currentIndex) {
          localStorage.removeItem("onBehafeOf");
          this.onBehafeOf = undefined;
        }
      }
    }
    else {
      if (this.userSummaryData?.teamsOwners) {
        this.mainAccount = this.userSummaryData.teamsOwners[index];
        this.getImgPath(this.mainAccount?.img);
        localStorage.setItem('mainAccount', JSON.stringify(this.mainAccount));
      }
    }
    this.currentIndex = index;
    localStorage.setItem('currentIndex', JSON.stringify(this.currentIndex));

    let profileId;
    let datasource;
    if (this.userSummaryData?.teamsOwners) {
      profileId = this.userSummaryData.teamsOwners[index].id;
      datasource = this.profilesDataSources.find(x => x.profileId == profileId)
    }
    if (datasource || index == 0) {
      this.profilesDataSource = datasource?.data;
      this.setProfileStatusNames();
      return;
    }

    if (this.userSummaryData.teamsOwners) {
      this.profileLinkService
        .GetLinkedByProfileId(this.userSummaryData.teamsOwners[index]?.id)
        .subscribe((source) => {
          if (source.status == ResponseStatus.Success) {
            let data = source.data;
            this.profilesDataSource = data;
            this.setProfileStatusNames();
            this.profilesDataSource.forEach(element => {
              let arabicName =
                !element.fullArabicName || element.fullArabicName.trim().length == 0
                  ? null
                  : element.fullArabicName;
              element.fullArabicName =
                arabicName || element.fullEnglishName;

              element.userDisplayName = (this.GetCurrentLanguage() == "en"
                ? element.fullEnglishName
                : element.fullArabicName) || element.fullEnglishName;
            });
            this.profilesDataSource?.sort(this.compare);

            this.profilesDataSources.push({ profileId: profileId, data: data });
            this.handleOnBehafeOf();
          }
        });
    }

  }
  selectProfile(items) {
    this.isSelectedUserChanged = true;

    if (items && items[0]) {
      this.onBehafeOf = items[0];
      localStorage.setItem('onBehafeOf', JSON.stringify(this.onBehafeOf));
    }
    else {
      localStorage.removeItem("onBehafeOf")
      this.onBehafeOf = null;
    }
    this.onBehafeOf = this.onBehafeOf ? Object.assign({}, this.onBehafeOf) : null;
  }
  openRelatedProfile() {
    this.isRelatedProfileOpen = !this.isRelatedProfileOpen;
    this.addStyleScrollTBody();

    this.checkPageReLoading();
    //  const modalRef = this.modalService.show(template ,  {class: 'modal-lg'});
  }
  addStyleScrollTBody() {
    const bodyEl = document.querySelector('body');
    if (!this.isRelatedProfileOpen)
      bodyEl.classList.remove('overflow-hidden');
    else {
      bodyEl.classList.add('overflow-hidden');
    }
  }

  closeModal() {
    if (!this.isRelatedProfileOpen) {
      return;
    }

    this.isRelatedProfileOpen = false;
    this.addStyleScrollTBody();

    this.checkPageReLoading();
  }
  onSelectAccount(data) {
    this.isSelectedUserChanged = true;
    const account = data[0];

    const index = data[1];
    this.selectDataSource(index);

    this.relatedProfilesTable?.resetTable();
  }
  openModal(template: any) {
    const modalRef = this.modalService.show(template);
  }
  compare(a, b) {
    if (a.userDisplayName.toLowerCase() < b.userDisplayName.toLowerCase()) {
      return -1;
    }
    if (a.userDisplayName.toLowerCase() > b.userDisplayName.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  onViewAccount(event) {
    this.addStyleScrollTBody();
  }

  onSetDefault(profileId: number) {
    if (profileId == this.currentProfile.id) {
      profileId = -1;
    }
    this._profileService
      .setDefault(profileId)
      .subscribe(source => {
        if (source?.status == ResponseStatus.Success) {
          if (profileId == -1) {
            this.currentProfile.defaultProfileId = this.currentProfile.id
          }
          else {
            this.currentProfile.defaultProfileId = profileId
          }
        }
      });
  }
  onRowClicked(event) {
    console.log('onRowClicked', event);

  }

  checkPageReLoading() {
    let shouldReloadOnClosingImpersonationPopup = this.isSelectedUserChanged && (this.shouldRefreshAfterImpersonation || !!this._impersonationService.currentUser) && !this.isRelatedProfileOpen;
    if (shouldReloadOnClosingImpersonationPopup) {
      this._forceRefreshPage.ForceRefreshPageService(true)
      window.location.reload();
    }
  }

  imagesDic: any = {};
  getImgPath(img) {
    if (this.imagesDic[img] || this.imagesDic[img + 'requesting']) {
      return;
    }

    if (img && !this.imagesDic[img]) {
      this.imagesDic[img + 'requesting'] = true;
      this.documentsService.getDocumentContent(img)
        .subscribe(
          event => {
            this.imagesDic[img + 'requesting'] = false;
            this.imagesDic[img] = this.documentsService.getBlobURL(event.body);
          },
          () => {
            this.imagesDic[img + 'requesting'] = false;
            this.imagesDic[img] = img;
          }
        );
    }
  }

  getImageSrc(img) {
    return this.imagesDic[img] || this.defaultAvatar;
  }
}
