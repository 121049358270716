<ul class="w-100">
  <li class="nav-item" *ngFor="let item of teamsOwners; let i = index" [ngClass]="{'active': i == this.currentIndex }">
    <div class="d-flex  align-self-start flex-direction">
      <div *ngIf="!item?.img" class="user-pic">
        <img [src]="defaultAvatar" [alt]="item?.userDisplayName" />
      </div>
      <div *ngIf="item?.img" class="user-pic">
        <img [src]="getImageSrc(item?.img)" [alt]="item?.userDisplayName" />
      </div>
      <div class="d-flex flex-column   {{i == this.currentIndex ? teamsOwners.length != 1 ? 'w-50' : 'w-75' : 'w-75'}}">
        <h5 class="align-items-center d-flex" (click)="selectAccount(item, i)" title="{{item?.userDisplayName}}"
          [ngClass]="{'font-weight-semibold': i == currentIndex}">
          <span class="account-name d-inline-block text-truncate w-100">{{item?.userDisplayName}}</span>
          <span class="lib-icon-triangle ng-star-inserted d-inline-block text-danger mx-1"
            popover="{{translation?.restrictedUser || 'Restricted User'}}" triggers="click" [outsideClick]="true"
            *ngIf="item?.profileStatus == profileStatus.Restricted"
            title="{{translation?.restrictedUser || 'Restricted User'}}"><span class="path1"></span><span
              class="path2"></span><span class="path3"></span></span>
          <span class="lib-icon-triangle ng-star-inserted d-inline-block text-danger mx-1"
            popover="{{translation?.deactivatedUser || 'Deactivated User'}}" triggers="click" [outsideClick]="true"
            *ngIf="item?.profileStatus == profileStatus.Deactivated"
            title="{{translation?.deactivatedUser || 'Deactivated User'}}"><span class="path1"></span><span
              class="path2"></span><span class="path3"></span></span>
        </h5>
        <ng-container *ngIf="i == currentIndex">
          <h6 class="text-truncate line-height14" *ngFor="let item of selectedItems" title="{{item[keyField]}}">
            {{item[keyField]}}
          </h6>
        </ng-container>
        <span class="title text-truncate d-none d-lg-block"
          *ngIf="isDeafult(item.id)">{{'accessManagement.defaultSwitcher' | translate}}</span>
        <a [routerLink]="profileLink" target="_blank" class="link-account text-truncate d-none d-lg-block"
          *ngIf="i== currentIndex" (click)="routeToProfile()"> {{'accessManagement.viewAccount' | translate}}</a>
        <button (click)="setDefault(item.id)" class="link-account text-truncate d-none d-lg-block text-justify"
          *ngIf="!isDeafult(item.id)"> {{'accessManagement.setAsDefault'| translate}}</button>

      </div>
      <span class="lib-icon-check-bold custom" *ngIf="i == currentIndex && teamsOwners?.length>1"></span>
    </div>

  </li>
</ul>