import { Component, OnInit } from '@angular/core';
import { ServiceManagementRoutes } from '@linkdev/service-management-library';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {

  showServiceCatalog: boolean = true;
  isAdvancedSearchOpen: boolean = false;
  translation: any;
  isA2HSBtn: boolean = false;
  deferredPrompt: any;

  workspaceLink = `${ServiceManagementRoutes.baseRoute}/${ServiceManagementRoutes.workspaceRoute}`;
  servicesLink: string = `${ServiceManagementRoutes.baseRoute}/${ServiceManagementRoutes.serviceCardsRoute}`;

  constructor(private translateService: TranslateService) {
    this.translateService.get("homePage").subscribe((trans) => {
      this.translation = trans;
      document.title = this.translation["documentTitle"] || "ServeBig";
    })
  }
  ngOnInit(): void {
    this.handleBrowserInstallPrompt();
  }

  handleBrowserInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.deferredPrompt = e;
      this.isA2HSBtn = true;
    });
  }

  appInstallHandler() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.isA2HSBtn = false;
      }
    });
  }

  serviceCatalogComponentLoaded(list) {
    this.showServiceCatalog = list.length > 0;
  }

  toggleAdvancedSearch() {
    this.isAdvancedSearchOpen = !this.isAdvancedSearchOpen;
  }
}
