import { NgModule } from "@angular/core";
import {AppointmentDynamicComponents_Dictionary, AppointmentsPublicRoutingModule, APPOINTMENT_CONFIG} from "@linkdev/appointment-library"
import { GoogleMapsComponent } from "@linkdev/portals-shared";
import { SCHEDULER_CONFIG } from "@linkdev/scheduler-library";
import { AppConfigService } from "../core/config/app-config.service";
import { Constants } from "../data/constants/constants";
import { AppointmentDynamicComponentsDictionary } from "./providers/appointment-dynamic-components-dictonary";
 
@NgModule({
  declarations: [],
  imports: [
    AppointmentsPublicRoutingModule,
  ],
  providers: [
    { provide: AppointmentDynamicComponents_Dictionary, useClass: AppointmentDynamicComponentsDictionary },
    {
      provide: SCHEDULER_CONFIG, useFactory: () => {
        return {
          baseUrl: AppConfigService.settings.scheduleApi,
          appointmentManagementUrl: AppConfigService.settings.appointmentApi,
        }
      }
    },
    {
      provide: APPOINTMENT_CONFIG, useFactory: () => {
        return {
          baseUrl: AppConfigService.settings.appointmentApi,
          serviceManagementUrl: AppConfigService.settings.serviceManagementUrl,
          accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
          breadcrumbs: Constants.breadcrumbs
        }
      }
    },
    { provide: "map-component", useValue: GoogleMapsComponent },
  ]
})
export class PortalAppointmentsHelperModule { }
