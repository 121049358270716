import { Injectable } from "@angular/core";
import { DynamicEditRequesterInfoComponentsDictionary, EditRequesterInfoComponentItem } from '@linkdev/shared-library';
import { CorporateRequsterInfoComponent, PersonalRequesterInfoComponent } from '@linkdev/access-manage-library';

@Injectable()
export class DynamicEditRequesterInfoDictionary implements DynamicEditRequesterInfoComponentsDictionary {
    public getDynamicComponents(): EditRequesterInfoComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: EditRequesterInfoComponentItem[] = [
        new EditRequesterInfoComponentItem(CorporateRequsterInfoComponent, "CorporateRequsterInfoComponent"),
        new EditRequesterInfoComponentItem(PersonalRequesterInfoComponent, "PersonalRequesterInfoComponent"),
    ]

}
