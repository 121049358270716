import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceManagementRoutes } from '@linkdev/service-management-library';

@Component({
  selector: 'lib-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: []
})
export class ErrorPageComponent implements OnInit {
  translationData: any;
  message: any;
  messageKey: any;

  workspaceLink: string = `/${ServiceManagementRoutes.baseRoute}/${ServiceManagementRoutes.workspaceRoute}`;

  /*
  Example
  this.router.navigate(['error'],{ state: { message: message} });
  */
  constructor(private translateService: TranslateService, private activeRoute: ActivatedRoute) {
    this.translateService.get("common").subscribe((trans) => {
      this.translationData = trans;
      this.message = this.translationData?.defaultErrorMessage || 'Something went wrong. Please try again.';

      //getting message key from browser state object 
      this.messageKey = this.activeRoute.snapshot.queryParamMap.get("message") || history.state.message;
      if (this.messageKey) {
        this.message = this.translationData[this.messageKey]
      }
    });
  }

  ngOnInit(): void {
  }
}