import { Component, OnInit } from '@angular/core';
import { LookupsRoutes } from '@linkdev/lookup-library';
import { Constants } from '../../data/constants/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  twitterAccount: any;
  facebookAccount: any;
  youtubeAccount: any;

  currentYear = new Date().getFullYear();

  // FAQsLink: string = `#/${LookupsRoutes.publicBaseRoute}/${LookupsRoutes.FAQsRoute}`;
  FAQsLink: any;
  aboutUsLink: any;
  contactUsLink: any;
  lawsLink: any;

  constructor() {
    this.twitterAccount = Constants.socialMediaAccounts.twitter;
    this.youtubeAccount = Constants.socialMediaAccounts.youtube;
    this.facebookAccount = Constants.socialMediaAccounts.facebook;
    this.FAQsLink = Constants.contentPages.faqs;
    this.aboutUsLink = Constants.contentPages.aboutUs;
    this.contactUsLink = Constants.contentPages.contactUs;
    this.lawsLink = Constants.contentPages.laws;
  }

  ngOnInit(): void { }

}
