import { AccessManagementRoutes } from '@linkdev/access-manage-library'
import { AppointmentsRoutes } from '@linkdev/appointment-library'
import { LookupsRoutes } from '@linkdev/lookup-library'
import { IConfig } from '@linkdev/portals-shared'
import { ServiceManagementRoutes } from '@linkdev/service-management-library'

export const Constants: IConfig = {
    maxLengthConfig: {
        textboxDefaultLength: 100,
        textAreaDefaultLength: 225
    },

    formioConfig: {
        apiUrl: "http://localhost:9001/"
    },

    socialMediaAccounts: {
        facebook: "https://www.facebook.com/",
        twitter: "https://twitter.com/",
        youtube: "https://www.youtube.com/"
    },

    breadcrumbs: [
        {
            title: 'home', link: '/', location: '/',
            children: [
                {
                    title: 'profile', link: `/${AccessManagementRoutes.profileRoute}`, location: `/${AccessManagementRoutes.profileRoute}`, routePrefix: `${AccessManagementRoutes.baseRoute}`
                },
                {
                    title: 'bookAnAppointment', link: `/${AppointmentsRoutes.bookAppointmentRoute}`, location: `/${AppointmentsRoutes.bookAppointmentRoute}`, routePrefix: `${AppointmentsRoutes.baseRoute}`
                },
                {
                    title: 'notifications', link: '/notifications', location: '/notifications'
                },
                {
                    title: 'workspace', link: `/${ServiceManagementRoutes.workspaceRoute}`, location: `/${ServiceManagementRoutes.workspaceRoute}`, routePrefix: `${ServiceManagementRoutes.baseRoute}`,
                },
                {
                    title: 'serviceCatalog', link: `/${ServiceManagementRoutes.serviceCardsRoute}`, location: `/${ServiceManagementRoutes.serviceCardsRoute}`, routePrefix: `${ServiceManagementRoutes.baseRoute}`
                },
                {
                    title: 'FAQs', link: `/${LookupsRoutes.FAQsRoute}`, location: `/${LookupsRoutes.FAQsRoute}`, routePrefix: `${LookupsRoutes.publicBaseRoute}`
                }
            ]
        },
    ],
    workspaceOverviewRowsLimit: 3,
    defaultLanguage: "en",
    allowRequestBrowseWallet: true,
    allowRequestCommentAttachment: true,
    allowMyRequestsHideChildren: true,
    contentPages: {        
    }
}
