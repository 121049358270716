import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { HeaderComponent } from './header.component';
import { FormControlModule, CoreModule, TableGridModule } from '@linkdev/shared-library';
// import { FormControlModule,  TableGridModule } from '@linkdev/shared-library';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { SwitchProfileComponent } from './components/switch-profile/switch-profile.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RelatedProfileTableComponent } from './components/related-profile-table/related-profile-table.component';
import { RelatedProfileTabsComponent } from './components/related-profile-tabs/related-profile-tabs.component';
import { TranslateService } from '@ngx-translate/core';
import { ProfileAnnouncementsModule, RelatedProfileInvitationActionsComponent, TeamMemberInvitationActionsComponent } from '@linkdev/access-manage-library';
import { DynamicAnnouncementComponentLoaderModule, SimpleAnnouncementComponent, AnnouncementComponentsDictionary } from '@linkdev/shared-library';
import { DynamicAnnouncemntComponentsDictionary } from 'src/app/portal-services-library/providers/dynamic-annoucement-components-dictonary';
import { PortalNotificationsLibraryModule } from 'src/app/portal-notifications-library/portal-notifications-library.module';
import { NotificationManagementModule } from '@linkdev/notification-management-library';
@NgModule({
    declarations: [
        LanguageSwitcherComponent,
        LoginButtonComponent,
        LogoutButtonComponent,
        HeaderComponent,
        RelatedProfileTabsComponent,
        RelatedProfileTableComponent,
        SettingsMenuComponent,
        RelatedProfileTableComponent,
        RelatedProfileTabsComponent,
        SwitchProfileComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        AppRoutingModule,
        TableGridModule,
        FormControlModule,
        DynamicAnnouncementComponentLoaderModule,
        PortalNotificationsLibraryModule,
        NotificationManagementModule,
        ProfileAnnouncementsModule
    ],
    exports: [HeaderComponent, RelatedProfileTabsComponent,
        RelatedProfileTableComponent, RelatedProfileInvitationActionsComponent,
        SimpleAnnouncementComponent,
        TeamMemberInvitationActionsComponent, DynamicAnnouncementComponentLoaderModule],
    providers: [{ provide: AnnouncementComponentsDictionary, useClass: DynamicAnnouncemntComponentsDictionary }]
})
export class HeaderModule {
  constructor(translate: TranslateService) {
    //Add Languages
    translate.addLangs(['en', 'ar']);
  }
}
