<footer class="footer d-none d-lg-block">
  <div class="container">
    <div class="footer-wrp d-flex justify-content-between align-items-center">
      <div class="copyright">
        {{ "footer.copyright" | translate }} {{currentYear}}
      </div>
      <div class="links-wrp d-flex justify-content-end">
        <ul class="footer-items mb-lg-0 d-flex">
        <li>
          <a [href]="contactUsLink" title="{{ 'footer.contactUs' | translate }}" target="_blank">
            {{ "footer.contactUs" | translate }}
          </a>
        </li>
        <li>
          <a [href]="FAQsLink" title="{{ 'footer.FAQs' | translate }}" target="_blank">
            {{ ("footer.FAQs" | translate) || "Frequently Asked Questions" }}
          </a>
        </li>
        <li>
          <a [href]="lawsLink" title="{{ 'footer.legalSide' | translate }}" target="_blank">
            {{ "footer.legalSide" | translate }}
          </a>
        </li>
        <li>
          <a [href]="aboutUsLink" title="{{ 'footer.aboutUs' | translate }}" target="_blank">
            {{ "footer.aboutUs" | translate }}
          </a>
        </li>
        <!-- <li>
                    <a [href]="FAQsLink" title="{{ 'footer.sitemap' | translate }}">
                      {{ "footer.sitemap" | translate }}
                    </a>
                  </li> -->
        </ul>
        <ul class="social-links mb-0 d-flex">
          <li class="">
            <a [href]="youtubeAccount" class="icon eda-youtube" target="_blank"
              title="{{ 'footer.followUsOnYoutube' | translate }}">
              <span class="sr-only">Visit youtube.com</span>
            </a>
          </li>
          <li class="">
            <a [href]="facebookAccount" class="icon icon-facebook" target="_blank"
              title="{{ 'footer.followUsOnFacebook' | translate }}">
              <span class="sr-only">Visit facebook.com</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
