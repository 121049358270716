import { AbstractDynamicComponentsDictionary, ComponentItem } from '@linkdev/shared-library';
import { Injectable } from "@angular/core";
import { EServiceModulesIntegrationInstance } from '@linkdev/service-management-library';

@Injectable()
export class InspectionDynamicComponentsDictionary implements AbstractDynamicComponentsDictionary {

    public getDynamicComponents(): ComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: ComponentItem[] = [
        new ComponentItem(EServiceModulesIntegrationInstance, "EServiceModulesIntegrationInstance"),
    ];

}