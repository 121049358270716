import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule, DynamicInspectionOutputComponentsDictionary } from '@linkdev/shared-library';
import { AppConfigService } from '../core/config/app-config.service';
import { Constants } from '../data/constants/constants';
import { Inspection_LIBRARY_CONFIG, InspectionDynamicComponents_Dictionary, InspectionLibraryPublicRoutingModule } from '@linkdev/inspection-library';
import { InspectionDynamicComponentsDictionary } from './providers/inspection-dynamic-components-dictonary';
import { ServiceManagementRoutes } from '@linkdev/service-management-library';
import { DynamicInspectionOutputDictionary } from './providers/dynamic-inspection-output-dictionary';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        InspectionLibraryPublicRoutingModule,
        CoreModule
    ],
    providers: [
        { provide: DynamicInspectionOutputComponentsDictionary, useClass: DynamicInspectionOutputDictionary },
        { provide: InspectionDynamicComponents_Dictionary, useClass: InspectionDynamicComponentsDictionary },
        {
            provide: Inspection_LIBRARY_CONFIG, useFactory: () => {
                return {
                    baseUrl: AppConfigService.settings.inspectionsApi,
                    portalUrl: AppConfigService.settings.publicPortalUrl,
                    accessManagementUrl: AppConfigService.settings.accessManagementUrl,
                    textboxMaxLength: Constants.maxLengthConfig.textboxDefaultLength,
                    textAreaMaxLength: Constants.maxLengthConfig.textAreaDefaultLength,
                    breadcrumbs: Constants.breadcrumbs,
                    inspectionsApi: AppConfigService.settings.inspectionsApi,
                    maxLengthConfig: Constants.maxLengthConfig,
                    isAdmin: false,
                    modulesRoutes: {
                        serviceManagementRoutes: ServiceManagementRoutes
                    }
                }
            }
        }
    ]
})
export class PortalInspectionLibraryHelperModule { }
