<app-header [IsDimmed]="dimHeader" class="app-header"></app-header>

<main class="main">
  <router-outlet></router-outlet>
</main>

<ng-container *ngIf="chatbotEnabled">
  <app-chatbot></app-chatbot>
</ng-container>
<app-footer class="app-footer"></app-footer>
<app-cookie-consent></app-cookie-consent>