import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentsService } from '@linkdev/document-manage-library';
import { AccessManagementRoutes, ProfileStatusEnum } from '@linkdev/access-manage-library';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-related-profile-tabs',
  templateUrl: './related-profile-tabs.component.html',
  styleUrls: ['./related-profile-tabs.component.css']
})
export class RelatedProfileTabsComponent implements OnInit {
  @Input() teamsOwners = [];
  @Input() selectedItems = [];
  @Input() keyField;
  @Input() currentProfile;
  @Input() currentIndex;
  @Output() onSelectAccount = new EventEmitter();
  @Output() onViewAccount = new EventEmitter();
  @Output() onSetDefault = new EventEmitter();

  defaultAvatar = "../../assets/images/avatar.png";
  profileStatus = ProfileStatusEnum;
  translation: any;

  profileLink: string= `${AccessManagementRoutes.baseRoute}/${AccessManagementRoutes.profileRoute}`;

  constructor(private documentsService: DocumentsService,
    public translateService: TranslateService) { 

      this.translateService.get("accessManagement").subscribe((trans) => {
        this.translation = trans;
      });
    }

  ngOnInit(): void {
    if (this.teamsOwners && this.teamsOwners.length) {
      this.teamsOwners.forEach(teamsOwner => {
        this.getImgPath(teamsOwner?.img);
      });
    }
  }
  selectAccount(account, index) {
    this.currentIndex = index;
    this.onSelectAccount.next([account, index]);
  }
  routeToProfile() {
    this.onViewAccount.next(true);
  }
  setDefault(profileId: number) {
    this.onSetDefault.emit(profileId);
    return false;
  }
  isDeafult(id: number) {
    //in case default account is removed
    if (this.currentProfile.defaultProfileId) {
      let index = this.teamsOwners.findIndex(x => x.id == this.currentProfile.defaultProfileId);
      if (id == this.currentProfile.id && index == -1) {
        return true;
      }
    }
    if (id == this.currentProfile.defaultProfileId ||
      (!this.currentProfile.defaultProfileId && id == this.currentProfile.id)) {
      return true
    }
    
    return false;
  }
  imagesDic: any = {};
  getImgPath(img) {
    if (this.imagesDic[img] || this.imagesDic[img + 'requesting']) {
      return;
    }

    if (img && !this.imagesDic[img]) {
      this.imagesDic[img + 'requesting'] = true;
      this.documentsService.getDocumentContent(img)
        .subscribe(
          event => {
            this.imagesDic[img + 'requesting'] = false;
            this.imagesDic[img] = this.documentsService.getBlobURL(event.body);
          },
          err => {
            this.imagesDic[img + 'requesting'] = false;
            this.imagesDic[img] = img;
          }
        );
    }
  }

  getImageSrc(img) {
    return this.imagesDic[img] || this.defaultAvatar;
  }
}
