import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormIOComponent } from './form-io.component';
import { Formio, FormioModule } from '@linkformbuilder/angular';
// import { FormioGrid } from '@formio/angular/grid';



@NgModule({
  declarations: [FormIOComponent],
  imports: [
    CommonModule,
    FormioModule,
    // FormioGrid,
  ],
  exports: [
    FormIOComponent
  ],
  providers: [
  ]
})
export class FormIOModule { }
