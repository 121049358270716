<nav class="bottom-mobile-bar d-lg-none" [ngClass]="{'dimmed-header' : IsDimmed}">
  <div class="container">
    <ul class="nav-wrp">
      <li class="nav-item">
        <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']">
          <span class="icon icon-home"></span>
          <span class="icon icon-home-full full"></span>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="workspaceLink" [routerLinkActive]="['active']">
          <span class="icon icon-workspace"></span>
          <span class="icon icon-workspace-full full"></span>
        </a>
      </li>
      <li *ngIf="currentUserName" class="nav-item" dropdown>
        <app-notifications-announcement [IsDimmed]="IsDimmed"></app-notifications-announcement>
      </li>
      <li class="nav-item">
        <div routerLinkActive="active">
          <a [routerLink]="profileMenuLink" *ngIf="hasLinkedOrTeams === true">
            <div *ngIf="!mainAccount?.img" class="user-pic">
              <img [src]="defaultAvatar" [alt]="mainAccount?.userDisplayName" />
            </div>
            <div *ngIf="mainAccount?.img" class="user-pic">
              <img [src]="getImageSrc(mainAccount?.img)" [alt]="mainAccount?.userDisplayName" />
            </div>
          </a>
          <a [routerLink]="profileMenuLink" *ngIf="hasLinkedOrTeams === false">
            <span class="icon icon-user"></span>
            <span class="icon icon-user-full full"></span>
          </a>
          <a [routerLink]="profileLink" class="d-none">
            <span class="icon icon-user"></span>
            <span class="icon icon-user-full full"></span>
          </a>
          <a routerLink="/settings-menu" class="d-none">
            <span class="icon icon-user"></span>
            <span class="icon icon-user-full full"></span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<div class="desktop-header d-none d-lg-block" [ngClass]="{'dimmed-header' : IsDimmed}">
  <div class="container">
    <nav class="header-wrapper" placement="right">
      <a routerLink="/" class="logo" tooltip="{{'common.portalName' | translate}}" (click)="closeModal()">
        <img src="../../assets/images/logo.png" alt="{{'common.portalName' | translate}}" />
      </a>
      <ul class="header-content">
        <!-- <li class="header-item header-search" [class.active]="isSearchOpen">
          <button class="search-btn header-btn" (click)="closeModal();openSearch()"
            tooltip="{{'header.search' | translate}}" placement="bottom">
            <span class="icon icon-search"></span>
          </button>
          <div class="general-search-bar">
            <input type="text" name="name" value="" class="search-input" placeholder="{{ 'header.search' | translate }}"
              tabindex="-1" />
            <a href="#" class="search-link header-btn" tabindex="-1">
              <span class="icon icon-search c-highlight-light"></span>
            </a>
          </div>
        </li> -->
        <li class="header-item header-workspace">
          <a [routerLink]="workspaceLink" [routerLinkActive]="['active']" class="header-btn"
            tooltip="{{'header.workspace' | translate}}" placement="right" (click)="closeModal()">
            <span class="icon icon-workspace"></span>
            <span class="icon icon-workspace-full full"></span>
          </a>
        </li>
        <li *ngIf="currentUserName" class="header-item header-nots" dropdown [insideClick]="false"
          (click)="closeModal()">
          <app-notifications-announcement [IsDimmed]="IsDimmed"></app-notifications-announcement>
        </li>

        <li *ngIf="hasLinkedOrTeams === true" class="header-item header-user-control">
          <ng-container *ngIf="currentUserName || isLoading; else login">
            <button type="button" class="user-info on-behalf-btn" [ngClass]="{'open': isRelatedProfileOpen }">
              <div *ngIf="!mainAccount?.img ;else imgDiv" class="user-pic" (click)="navigateToProfile()">
                <img [src]="defaultAvatar" [alt]="userDisplayName" />
              </div>
              <ng-template #imgDiv>
                <div class="user-pic" (click)="navigateToProfile()">
                  <img [src]="getImageSrc(mainAccount?.img)" [alt]="mainAccount?.userDisplayName" />
                </div>
              </ng-template>

              <ng-container *ngIf="!isRelatedProfileOpen">
                <div class="d-flex flex-column align-items-start " (click)="navigateToProfile()">
                  <span class="d-flex">
                    <span class="user-name text-truncate" title="{{mainAccount?.userDisplayName || userDisplayName}}">
                      {{mainAccount?.userDisplayName || userDisplayName}}
                    </span>
                    <span class="lib-icon-triangle text-danger mx-1"
                      popover="{{translation?.restrictedUser || 'Restricted User'}}" triggers="click"
                      [outsideClick]="true" *ngIf="mainAccount?.profileStatus == profileStatus.Restricted"
                      title="{{translation?.restrictedUser || 'Restricted User'}}">
                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                    </span>
                    <span class="lib-icon-triangle text-danger mx-1"
                      popover="{{translation?.deactivatedUser || 'Deactivated User'}}" triggers="click"
                      [outsideClick]="true" *ngIf="mainAccount?.profileStatus == profileStatus.Deactivated"
                      title="{{translation?.deactivatedUser || 'Deactivated User'}}">
                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                    </span>
                  </span>
                  <span class="d-flex">
                    <span class="onbehafeof-name text-truncate" title="{{onBehafeOf?.userDisplayName}}">
                      {{onBehafeOf?.userDisplayName}}
                    </span>
                    <span class="lib-icon-triangle  text-danger mx-1"
                      popover="{{translation?.restrictedUser || 'Restricted User'}}" triggers="click"
                      [outsideClick]="true" *ngIf="onBehafeOf?.profileStatus == profileStatus.Restricted"
                      title="{{translation?.restrictedUser || 'Restricted User'}}">
                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                    </span>
                    <span class="lib-icon-triangle  text-danger mx-1"
                      popover="{{translation?.deactivatedUser || 'Deactivated User'}}" triggers="click"
                      [outsideClick]="true" *ngIf="onBehafeOf?.profileStatus == profileStatus.Deactivated"
                      title="{{translation?.deactivatedUser || 'Deactivated User'}}">
                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                    </span>
                  </span>
                </div>
              </ng-container>
              <span class="lib-icon-down-head-arrow custom" (click)="openRelatedProfile()"
                [ngClass]="{'arrow-up': isRelatedProfileOpen, 'arrow-down': !isRelatedProfileOpen}"></span>
            </button>

            <span class="notify-bult rounded"></span>
            <app-alert-message class="my-2 swbtn-profile" [hasClose]="false" alertType="message" [active]="true"
              alertText="{{'accessManagement.switchProfileHint' | translate}}">
            </app-alert-message>
          </ng-container>
        </li>
        <li *ngIf="!hasLinkedOrTeams" class="header-item header-user-control">
          <ng-container *ngIf="currentUserName || isLoading; else login">
            <button type="button" (click)="navigateToProfile()" class="user-info">
              <div *ngIf="!userSummaryData?.img" class="user-pic">
                <img [src]="defaultAvatar" [alt]="userSummaryData?.userDisplayName" />
              </div>
              <div *ngIf="userSummaryData?.img" class="user-pic">
                <img [src]="getImageSrc(userSummaryData?.img)" [alt]="userSummaryData?.userDisplayName" />
              </div>
              <span class="user-name text-truncate">{{userSummaryData?.userDisplayName || userDisplayName}}</span>
              <span class="lib-icon-triangle text-danger mx-1"
                popover="{{translation?.restrictedUser || 'Restricted User'}}" triggers="click" [outsideClick]="true"
                *ngIf="userSummaryData?.profileStatus == profileStatus.Restricted"
                title="{{translation?.restrictedUser || 'Restricted User'}}">
                <span class="path1"></span><span class="path2"></span><span class="path3"></span>
              </span>
              <span class="lib-icon-triangle text-danger mx-1"
                popover="{{translation?.deactivatedUser || 'Deactivated User'}}" triggers="click" [outsideClick]="true"
                *ngIf="userSummaryData?.profileStatus == profileStatus.Deactivated"
                title="{{translation?.deactivatedUser || 'Deactivated User'}}">
                <span class="path1"></span><span class="path2"></span><span class="path3"></span>
              </span>
            </button>
          </ng-container>
        </li>
        <li class="header-item header-settings" dropdown [insideClick]="true">
          <button class="settings-btn header-btn" dropdownToggle tooltip="{{'header.settings' | translate}}"
            placement="right" (click)="closeModal()">
            <span class="icon icon-settings"></span>
          </button>
          <!-- this dropdown doesn't have dropdownMenu directive as it interfers with functions and listeners we set in app.component, instead we are using css -->
          <app-settings-menu></app-settings-menu>
        </li>
      </ul>
    </nav>
  </div>
</div>

<ng-template #login>
  <app-login-button></app-login-button>
</ng-template>


<ng-container *ngIf="hasLinkedOrTeams && isRelatedProfileOpen">
  <div class="overlay  d-none d-lg-block" [ngClass]="{'is-open': isRelatedProfileOpen}">
    <!-- <div class="my-modal"> -->
    <div class="user-controler-modal-content">
      <div class="conatiner  user-control-container ">
        <div class="row">
          <div class="col-9 account-table" *ngIf="profilesDataSource.length > 0">
            <app-related-profile-table #relatedProfilesTable [selectedItems]="onBehafeOf? [onBehafeOf] : []"
              (onBehafeOfSelected)="selectProfile($event)" [tableHeader]="tableHeader"
              [tableContent]="profilesDataSource" [totalRecords]="profilesDataSource.length" [keyField]="keyField"
              gridTitle="{{'accessManagement.onBehalfOf' | translate}}" (onRowClicked)="onRowClicked($event)">
            </app-related-profile-table>


            <button type="button" class="btn-link mx-auto close-custom rounded"
              (click)="closeModal()">{{'accessManagement.close' | translate}}</button>
          </div>
          <div class="col-9 " *ngIf="profilesDataSource.length  == 0">
          </div>
          <div class="col-3 account-tabs" [ngClass]="{'rounded': profilesDataSource.length  == 0}">
            <div class="row">
              <app-related-profile-tabs [currentProfile]="currentProfile" [currentIndex]="currentIndex"
                class="d-block w-100" (onViewAccount)="onViewAccount($event)" (onSetDefault)="onSetDefault($event)"
                [teamsOwners]="userSummaryData.teamsOwners" [selectedItems]="onBehafeOf? [onBehafeOf] : []"
                (onSelectAccount)="onSelectAccount($event)" [keyField]="keyField"></app-related-profile-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</ng-container>
<!-- </ng-template> -->
