import { Injectable } from "@angular/core";
import { DynamicInspectionOutputComponentsDictionary, InspectionOutputComponentItem } from '@linkdev/shared-library';
import { InspectionReportComponent } from "@linkdev/inspection-library";

@Injectable()
export class DynamicInspectionOutputDictionary implements DynamicInspectionOutputComponentsDictionary {
    public getDynamicComponents(): InspectionOutputComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: InspectionOutputComponentItem[] = [
        new InspectionOutputComponentItem("DefaultInspectionReportComponent", InspectionReportComponent),
    ]

}
