import { Component, OnInit, Output, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '@linkdev/shared-library';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.css']
})
export class FormTemplateComponent implements OnInit {
  isOpenTabs: boolean;
  item = {img: undefined,
    userDisplayName: 'Alexie John'};
  toggle: any;
  draftsTableHeader: { field: string; header: any; dataType: string; editable: boolean; alternateField: string; clickable?: boolean , isSelect?: boolean, selectList?: any[]}[];
  selectedItems: any;
  scientificOrganizationId: any[]  = [];
  customIcon = 'lib-icon-add';
  titleIconText = 'ay 7ga';
  dropdownItems: any[];
  test2: any[];
  test1: any;
  constructor(private modalService: BsModalService, public dialogModal: ModalService) { }
  modalRef: BsModalRef;
  @Output() linkClick = new EventEmitter();
  @ViewChild("addTransModalRef") addTransModalRef: any;
  innerValue = 3
  showSelectOption = false;
  currentCultureId = 'LDN';
  currentIdTest = 3;
  defaultAvatar = "../../assets/images/avatar.png";
  otheradminsServiceUrl = `https://beta.accessmanagement.servebig.linkdev.com/api/Principal/users?userName={0}`;
  otherAdminsList = [];
  peopleSearchItems = [];
  modifiedBy;
  ngOnInit(): void {
    this.scientificOrganizationId = [1];
    this.draftsTableHeader = [
      { field: 'ID', header:  'Id', dataType: "string", editable: false, alternateField: "Id" },
      { field: 'PROFILE', header: 'Profile', dataType: "string", editable: false, alternateField: "Profile" },
      { field: 'EMAIL', header:  'Email', dataType: "string", editable: false, alternateField: "Email" },
      { field: 'Role', header:  'Role', dataType: "icon", editable: false, alternateField: "Role", isSelect: true, selectList: this.itemsTest },
    ];
    // this.RtlSupport.getComponentDirection().subscribe(
    //   next => {
    //     this.RtlClass = next == 'ar' ? true : false;
    //   }
    // )
  }
  pageChange($event: any){
  }
  itemsTest = [
    { id: 1, displayName: 'NY' },
    { id: 2, displayName: 'RM' },
    { id: 3, displayName: 'LDN' },
    { id: 4, displayName: 'IST' },
    { id: 5, displayName: 'PRS' }
  ];
  itemsTest2 = [
    { id: 1, displayName: 'NY' },
    { id: 2, displayName: 'RM' },
    { id: 3, displayName: 'LDN' },
    { id: 4, displayName: 'IST' },
    { id: 5, displayName: 'PRS' }
  ];
  cities = [
    { displayName: 'New York', id: 'NY', added: true  },
    { displayName: 'Rome', id: 'RM', added: false },
    { displayName: 'London', id: 'LDN', added: true  },
    { displayName: 'Istanbul', id: 'IST', added: false },
    { displayName: 'Paris', id: 'PRS', added: false }
  ];
  tableHead = [
    { field: 'vin', header: 'Vin', added: true },
    { field: 'year', header: 'Year', added: false },
    { field: 'brand', header: 'Brand' , added: true},
    { field: 'color', header: 'Color', added: true },
  ];

  tableContent = [
    { "brand": "VW", "year": 2012, "color": "Orange", "vin": "dsad231ff" },
    { "brand": "Audi", "year": 2011, "color": "Black", "vin": "gwregre345" },
    { "brand": "Renault", "year": 2005, "color": "Gray", "vin": "h354htr" },
    { "brand": "BMW", "year": 2003, "color": "Blue", "vin": "j6w54qgh" },
    { "brand": "Mercedes", "year": 1995, "color": "Orange", "vin": "hrtwy34" },
    { "brand": "Volvo", "year": 2005, "color": "Black", "vin": "jejtyj" },
    { "brand": "Honda", "year": 2012, "color": "Yellow", "vin": "g43gr" },
    { "brand": "Jaguar", "year": 2013, "color": "Orange", "vin": "greg34" },
    { "brand": "Ford", "year": 2000, "color": "Black", "vin": "h54hw5" },
    { "brand": "Fiat", "year": 2013, "color": "Red", "vin": "245t2s" }
  ]

  checkInputList = [{
    "name":"option 1",
    "id":"option1"
  },
  {
    "name":"option 2",
    "id":"option2"
  },
  {
    "name":"option 3",
    "id":"option3"
  }]


  onCancel() {
    this.modalRef.hide();
  }
  save() {

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openConfirmModal(modalElemRef) {
    this.modalRef = this.modalService.show(this[modalElemRef]);
    this.linkClick.emit(this.modalRef);
    this.dialogModal.modalHandler('normal-modal');
  }
  FilterTypeEnum = {
    GreaterOrEquals: 'GreaterOrEquals',
    LessOrEquals: 'LessOrEquals',
    Equals: 'Equals'

  }
  selectionModeEnum = {
    single: 'single',
    multiple: 'multiple'
  }
  totalSubmittedRequestsCount = 40;
  translation = undefined;
  serviceList = [];
  searchCriteria = {
    keyword: undefined,
    status: undefined,
    creationDateFrom: undefined,
    creationDateTo: undefined,
    serviceName: undefined,
    lastActionDate: undefined
  }
  isSearchOpen = true;
  isAdvancedSearchOpen = true;
  searchTags = [];
  myRequests = {
    submittedRequests: [],
    drafts: [
      {  ID : 'Amel youssef', PROFILE: 'requestName', EMAIL: 'creationDate', Role: 'assign'}
      ,{  ID : 'Paul porker', PROFILE: 'requestName', EMAIL: 'creationDate',  Role: 'assigned with 3 other'}
      ,{  ID : 'Test 7ga', PROFILE: 'requestName', EMAIL: 'creationDate', Role: 'assigned with 3 other'}
      ,{  ID : 'Amel youssef', PROFILE: 'nbn', EMAIL: 'creationDate', Role: 'assign'}
    ]
  }
  totalDraftsCount = 3;
  statusList = [];
  isInvalidLastActionDate = undefined
  maxDate= new Date();
  editModeLink;
  linkableEditMode;
  viewModeLink;
  editModeLinkSegmentProp;
  startPage;
  isInvalidDate;
  onAddLinkClicked(d?) {}
  onConfirmDeleteClick(d?) {}
  updateCorrespondingProp(d?) {}
  onSortOrPagerClick(d?, f?) {}
  toggleSearch() {}
  addKeywordSearch(d?) {}
  onFilter(d?) {}
  toggleAdvancedSearch(d?) {}
  onClearTag(d?) {}
  onClearFilter(d?) {}
  onDateChange(d?, f?, y?) {}
  addFilterCriteria(d?, f?, y?) {}
  getSelectedValue(d?, f?) {}
  onSelectedItems(d) {

  }
  getV() {
  }
  openTabs() {
    this.isOpenTabs = !this.isOpenTabs;
  }
  onSelectAccount(d) {
    this.isOpenTabs = false;
  }
  onCustomIconClick(event) {
   console.log('onCustomIconClick', event);
  }
  onTextClick(event) {
    console.log('onTextClick', event);
   }
  changeFn(event) {
    console.log('changeFn', event);
   }
   deleteItem(event) {
    console.log('deleteItem', event);
   }
   itemsRoleWithEmployes = [
     {'role': 'admin' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
     {'role': 'admin2' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
     {'role': 'admin3' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
     {'role': 'inspector' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
     {'role': 'inspector2' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
     {'role': 'inspector3' , 'employesName': ['Amel', 'Amel2', 'Amel3']},
   ]
   employees  = [
    {
      'roles': 'admin' , 'displayName': 'Amel Youssef', 
      'selectedSections' : ['2 admin','3 admin'], 
    'sections' : [{
      'id': '0 admin',
      'displayName': 'select All'
    },{
      'id': '1 admin',
      'displayName': 'section1'
    },
    {
    'id': '2 admin',
    'displayName': 'section2'
  },
  {
    'id': '3 admin',
    'displayName': 'section3'
  },
  {
    'id': '4 admin',
    'displayName': 'section4'
  },
  {
    'id': '5 admin',
    'displayName': 'section5'
  }]},
    {'roles': 'inspector' , 'displayName': 'Amel2 Youssef',
    'selectedSections' : ['1 inspector','5 inspector'],
      'sections' : [
        {
          'id': '0 inspector',
          'displayName': 'select All'
        },{
        'id': '1 inspector',
        'displayName': 'section1'
      },
      {
       'id': '2 inspector',
       'displayName': 'section2'
     },
     {
       'id': '3 inspector',
       'displayName': 'section3'
     },
     {
       'id': '4 inspector',
       'displayName': 'section4'
     },
     {
       'id': '5 inspector',
       'displayName': 'section5'
       }]},
    {'roles': 'assistant' , 'displayName': 'Amel3 Youssef', 
    'selectedSections' : ['4 assistant'],
      'sections' : [
        {
          'id': '0 assistant',
          'displayName': 'select All'
        },{
        'id': '1 assistant',
        'displayName': 'section1'
      },
      {
       'id': '2 assistant',
       'displayName': 'section2'
     },
     {
       'id': '3 assistant',
       'displayName': 'section3'
     },
     {
       'id': '4 assistant',
       'displayName': 'section4'
     },
     {
       'id': '5 assistant',
       'displayName': 'section5'
       }]},
   ];

   onChangePermission(event, roleArg) {
     console.log('event', event, roleArg);
     if (event) {
     event.map((item) => this.employees.map((role) =>  {
       if (role.selectedSections) {
          role.selectedSections.
          map((selectedItem: any) => {
            if  (parseInt(selectedItem) == parseInt(item)) { 
              if  (selectedItem != item) {
                let index = role.selectedSections.indexOf(selectedItem);
                if (index !== -1) {
                  role.selectedSections.splice(index, 1);
                }
              } else {
                role.selectedSections = event;
              }
            }       
          });
        } else {
          if (role.roles == event[0].split(" ")[1]) {
            role.selectedSections = event;
          }
        }
      })
      );
    } else {
      this.employees.map((role) =>  { if (roleArg.roles == role.roles)    role.selectedSections = event; })
    }
     this.employees  = JSON.parse(JSON.stringify(this.employees ));
   }
   getRoles() {
     return this.employees ;
   }
   getSelected(data) {
     console.log('getSelected', data);
     return data;
   }
   onLanguageChange(d) {
     console.log('onChangeFn', d);
     
   }
   onLanguageChange1(d) {
    console.log('onLanguageChange1', d);
    
  }
  onLanguageChange2(d) {
    console.log('onLanguageChange2', d);
    
  }
  addFilterCriteriat ( o , t, th) {
    console.log('addFilterCriteriat', o , t, th);
    console.log('modifiedBy', this.modifiedBy)
  }
  setPeopleSearchItems(e) {
    console.log('setPeopleSearchItems', e);
    
  }
  onDropdownOpenHandle(item) {
    this.dropdownItems = [];
      this.dropdownItems.push({ title:  "Mark As Read", action: (rowData) => { this.markAsReadModal('ReleaseModalRef', 'markAsRead') }});
      this.dropdownItems.push({ title:  "Delete", action: (rowData) => this.openConfirmModal(this.addTransModalRef)});
      console.log('dropdownItems', this.dropdownItems, item);
      
    }
  markAsReadModal(modalElemRef: any, selectedAction ) { 
  }
  onTest1Change(event) {
    this.test2 = [];
    this.test2 = [...event];
    console.log('onTest1Change', event, this.test2 , this.test1);
    // this.itemsTest2.map((item) => {
    //   if (item.id == event) {
    //     item.checked = true;
    //   }else {
    //     item.checked = false;
    //   }
    // })
    // this.itemsTest2[1].checked = true;
    // this.itemsTest2 = [...this.itemsTest2]
  }
  tedetect() {
    console.log( this.test2 , this.test1);
  }
  testValuel;
  updateOriginalQuestionScore(t) {
    console.log('updateOriginalQuestionScore', t);
    
  }
  inspectionDescription;
  isReadOnly = false;
}
