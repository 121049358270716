import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './homepage.component';
import { CoreModule, FormControlModule } from '@linkdev/shared-library';
import { RouterModule } from '@angular/router';
import { ServiceListModule } from '@linkdev/service-management-library';
import { BookingModule } from '@linkdev/appointment-library';
import { FormsModule } from '@angular/forms';
import { SchedulerLibraryModule } from '@linkdev/scheduler-library';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [HomepageComponent],
  imports: [
    CommonModule,
    CoreModule,
    ServiceListModule,
    RouterModule,
    CommonModule,
    CoreModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    BookingModule,
    SchedulerLibraryModule,
    FormControlModule,
    FormsModule,
  ],
  providers: []
})
export class HomepageModule { }
