import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CoreModule,
  FormControlModule,
  OIDC_CONFIG,
  Helper,
  SystemConfigurationService,
  ForceRefreshPageService,
  HttpService,
  LookupsService,
  UserService,
  LoaderService,
  HttpLoaderInterceptor,
  IMPERSONATION_CONFIG,
  GuardService,
  PrintService,
  PermissionGuard,
  PermissionsService,
  PermissionModule,
  SHARED_CONFIG
} from '@linkdev/shared-library';
import { AppConfigModule } from './core/config/app-config.module';
import { TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppConfigService } from './core/config/app-config.service';
import { HeaderFooterModule } from './header-footer/header-footer.module';
import { CookieConsentModule } from './cookie-consent/cookie-consent.module';
import { FormTemplateModule } from './form-template/form-template.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HomepageModule } from './homepage/homepage.module';
import { PostActivationComponent } from './post-activation/post-activation.component';
import { SearchComponent } from './search/search.component';
import { Constants } from './data/constants/constants';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WebStorageStateStore } from 'oidc-client';
import { ImpersonationService, PublicProfileService } from '@linkdev/access-manage-library';
import localeAr from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';
import { HandleLogin } from './handleLogin/handle-login.component';
registerLocaleData(localeAr);
import { PortalNotificationsLibraryModule } from './portal-notifications-library/portal-notifications-library.module';
import { FormioAppConfig } from '@linkformbuilder/angular';
import { SHARED_Portal_CONFIG, SharedModule } from '@linkdev/portals-shared';
import { PortalConfigurationsLibraryModule } from './portal-configurations-library/portal-configurations-library.module';
import { FormIOModule } from './form-io/form-io.module';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { PortalServicesHelperModule } from './portal-services-library/portal-services.helperModule';
import { PortalProfileHelperModule } from './portal-profile-library/portal-profile-helper.module';
import { PortalAppointmentsHelperModule } from './portal-appointments-library/portal-appointments.helperModule';
import { PortalLookupLibraryHelperModule } from './portal-lookup-library/portal-lookup-library.helperModule';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { PortalInspectionLibraryHelperModule } from './portal-inspection-library/portal-inspection-library.helperModule';

export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    ChatbotComponent,
    PostActivationComponent,
    HandleLogin,
    SearchComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    FormControlModule,
    HeaderFooterModule,
    AppConfigModule,
    AppRoutingModule,
    PortalServicesHelperModule,
    HomepageModule,
    CookieConsentModule,
    FormTemplateModule,
    PortalProfileHelperModule,
    PortalAppointmentsHelperModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormIOModule,
    PortalNotificationsLibraryModule,
    PortalInspectionLibraryHelperModule,
    SharedModule,
    PortalConfigurationsLibraryModule,
    PortalLookupLibraryHelperModule,
    PermissionModule
  ],
  providers: [
    HttpService,
    GuardService,
    LoaderService,
    PublicProfileService,
    UserService,
    PrintService,
    ImpersonationService,
    SystemConfigurationService,
    ForceRefreshPageService,
    PermissionsService,
    PermissionGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    { provide: OIDC_CONFIG, useFactory: userConfigFactory, deps: [AppConfigService] },
    { provide: LOCALE_ID, useValue: Helper.getCookieValue("lang") || Constants.defaultLanguage },
    { provide: "formIOComponentKey", useValue: "FormIOBaseComponent" },
    { provide: "lookupsApiUrl", useFactory: () => AppConfigService.settings.lookupsApi },
    { provide: "configurationsApiUrl", useFactory: () => AppConfigService.settings.configurationsApiUrl },
    { provide: IMPERSONATION_CONFIG, useClass: ImpersonationService },
    {
      provide: FormioAppConfig, useFactory: () => {
        return {
          appUrl: AppConfigService.settings.formioUrl,
          apiUrl: AppConfigService.settings.formioUrl,
        };
      },
    },
    { provide: "defaultLanguage", useValue: Constants.defaultLanguage },
    { provide: "allowRequestBrowseWallet", useValue: Constants.allowRequestBrowseWallet },
    { provide: "allowRequestCommentAttachment", useValue: Constants.allowRequestCommentAttachment },
    { provide: "allowMyRequestsHideChildren", useValue: Constants.allowMyRequestsHideChildren },
    {
      provide: SHARED_Portal_CONFIG, useFactory: () => {
        return {
          breadcrumbs: Constants.breadcrumbs,
          otpConfig: AppConfigService.settings.otpConfig
        }
      }
    },  
    {
      provide: "audit-config", useFactory: () => {
        return {
          excludedValues: [],
          metaData: null,
        };
      }
    },
    {
      provide: SHARED_CONFIG,
      useFactory: () => {
        return {
          baseUrl: AppConfigService.settings.accessManagementUrl,
          portalUrl: AppConfigService.settings.publicPortalUrl,
          //auditingUrl: AppConfigService.settings.auditingUrl,
          breadcrumbs: Constants.breadcrumbs,
          isAdmin: false,
        };
      },
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(translate: TranslateService) {
    //Add Languages
    translate.addLangs(['en', 'ar']);
  }
}

export function userConfigFactory(appConfig: AppConfigService) {
  return new Promise<any>((resolve) => {
    appConfig.load().then((config) => {
      let oidcConfig = {
        authority: config.oidcConfig.identityServerUrl,
        client_id: config.oidcConfig.clientId,
        redirect_uri: config.oidcConfig.redirectUri,
        silent_redirect_uri: config.oidcConfig.silentSignIn,
        response_type: config.oidcConfig.responsetype,
        scope: config.oidcConfig.scopes,
        automaticSilentRenew: true,
        post_logout_redirect_uri: config.oidcConfig.postLogoutRedirectUri,
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: localStorage }),
        ui_locales: Helper.getCookieValue("lang") || Constants.defaultLanguage,
        providerName: config.oidcConfig.providerName,
        addAccessTokenExpiredEvent: config.oidcConfig.registerTokenExpiredEvent == null ? true :
          typeof (config.oidcConfig.registerTokenExpiredEvent) == "boolean" ? config.oidcConfig.registerTokenExpiredEvent :
            config.oidcConfig.registerTokenExpiredEvent === "true",
      }
      resolve(oidcConfig);
    });

  });

}


