import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule, FormControlModule, TableGridModule } from '@linkdev/shared-library';
import { Notification_CONFIG, NotificationsRoutes, NotificationManagementModule } from '@linkdev/notification-management-library';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigService } from '../core/config/app-config.service';
import { Constants } from '../data/constants/constants';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TableGridModule,
    CoreModule,
    FormControlModule,
    NotificationManagementModule,
    RouterModule.forChild(NotificationsRoutes),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide: Notification_CONFIG, useFactory: () => {
      return {
        baseUrl: AppConfigService.settings.notificationBaseUrl,
        notificationApi: AppConfigService.settings.notificationApi,
        publicPortalUrl: AppConfigService.settings.publicPortalUrl,
        accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
        lookupsApi: AppConfigService.settings.lookupsApi,
        breadcrumbs: Constants.breadcrumbs,
        textboxMaxLength: Constants.maxLengthConfig.textboxDefaultLength,
        textAreaMaxLength: Constants.maxLengthConfig.textAreaDefaultLength,
        maxLengthConfig: Constants.maxLengthConfig,
      };
    }
  }]
})
export class PortalNotificationsLibraryModule { }