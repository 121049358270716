import { NgModule } from '@angular/core';
import { AbstractDynamicComponentsDictionary, DynamicViewRequesterInfoComponentsDictionary, DynamicEditRequesterInfoComponentsDictionary, DynamicWorkspaceTabComponentsDictionary, DynamicRequestOutputComponentsDictionary, DynamicPaymentMethodComponentsDictionary, Helper, AbstractDynamicFormIODictionary } from '@linkdev/shared-library';
import { ServiceManagementRoutingModule, SERVICE_MANAGEMENT_CONFIG } from '@linkdev/service-management-library';
import { DOCUMENT_MANAGEMENT_CONFIG } from '@linkdev/document-manage-library';
import { AppConfigService } from '../core/config/app-config.service';
import { DynamicComponentsDictionary } from './providers/dynamic-components-dictonary';
import { SERVICE_SAMPLE_CONFIG } from '@linkdev/request-sample-library';
import { DynamicViewRequesterInfoDictionary } from './providers/dynamic-view-requester-info-dictionary';
import { DynamicRequestOutputDictionary } from './providers/dynamic-request-output-dictionary';
import { DynamicEditRequesterInfoDictionary } from './providers/dynamic-edit-requester-info-dictionary';
import { AccessManagementRoutes } from '@linkdev/access-manage-library';
import { MOTA_SERVICE_CONFIG } from '@linkdev/mota-service-library';
import { DynamicPaymentMethodsDictionary } from './providers/dynamic-payment-method-dictonary';
import { DynamicWorkspaceComponentsDictionary } from './providers/dynamic-workspace-components-dictonary';
import { DynamicWorkspaceCardsDictionary } from './providers/dynamic-workspace-cards-dictonary';
import { DynamicWorkspaceCardComponentsDictionary } from '@linkdev/shared-library';
import { Constants } from '../data/constants/constants';
import { RECAPTCHA_V3_SITE_KEY, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { DynamicFormIOComponentsDictionary } from './providers/dynamic-formio-components-dictonary';

@NgModule({
    declarations: [],
    imports: [
        ServiceManagementRoutingModule
    ],
    providers: [
        {
            provide: DOCUMENT_MANAGEMENT_CONFIG, useFactory: () => {
                return {
                    baseUrl: AppConfigService.settings.documentManagementUrl,
                    portalUrl: AppConfigService.settings.publicPortalUrl,
                    isAdmin: false,
                };
            }
        },
        {
            provide: SERVICE_MANAGEMENT_CONFIG,
            useFactory: () => {
                return {
                    accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
                    portalUrl: AppConfigService.settings.publicPortalUrl,
                    baseUrl: AppConfigService.settings.serviceManagementUrl,
                    breadcrumbs: Constants.breadcrumbs,
                    workspaceOverviewRowsLimit: Constants.workspaceOverviewRowsLimit,
                    isAdmin: false,
                    modulesRoutes: {
                        accessManagementRoutes: AccessManagementRoutes
                    },
                    generalConfig: {
                        // hideWorkspacePriority: true,
                        // hideWorkspaceMediator: true,
                        // hideWorkspaceParentRequest: true,
                        // hideWorkspaceHideChildrenFilter: true,
                        // hideWorkspaceDraftsIfEmpty: true,

                        // showReviewRequestNextPrev: true,
                        requestNextPrevScrollUp: true,

                        swiperConfig: {
                            // defaultSlidesPerView: 3,
                            // defaultSpaceBetween: 6,
                            // defaultClass: 'justify-content-around',
                            //processId: customSittings
                            //4: {
                            //    slidesPerView: 4,
                            //    class: 'justify-content-between'
                            //},
                        },
                    }
                };
            },
        },
        {
            provide: SERVICE_SAMPLE_CONFIG,
            useFactory: () => {
                return {
                    accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
                    portalUrl: AppConfigService.settings.publicPortalUrl,
                    baseUrl: AppConfigService.settings.serviceManagementUrl,
                    breadcrumbs: Constants.breadcrumbs,
                    workspaceOverviewRowsLimit: Constants.workspaceOverviewRowsLimit,
                    isAdmin: false,
                };
            },
        },
        {
            provide: MOTA_SERVICE_CONFIG,
            useFactory: () => {
                return {
                    accessManagmentUrl: AppConfigService.settings.accessManagementUrl,
                    portalUrl: AppConfigService.settings.publicPortalUrl,
                    baseUrl: AppConfigService.settings.serviceManagementUrl,
                    breadcrumbs: Constants.breadcrumbs,
                    workspaceOverviewRowsLimit: Constants.workspaceOverviewRowsLimit,
                    isAdmin: false,
                };
            },
        },
        { provide: AbstractDynamicFormIODictionary, useClass: DynamicFormIOComponentsDictionary },
        { provide: DynamicWorkspaceTabComponentsDictionary, useClass: DynamicWorkspaceComponentsDictionary },
        { provide: DynamicWorkspaceCardComponentsDictionary, useClass: DynamicWorkspaceCardsDictionary },
        { provide: AbstractDynamicComponentsDictionary, useClass: DynamicComponentsDictionary },
        { provide: DynamicEditRequesterInfoComponentsDictionary, useClass: DynamicEditRequesterInfoDictionary },
        { provide: DynamicViewRequesterInfoComponentsDictionary, useClass: DynamicViewRequesterInfoDictionary },
        { provide: DynamicRequestOutputComponentsDictionary, useClass: DynamicRequestOutputDictionary },
        { provide: DynamicPaymentMethodComponentsDictionary, useClass: DynamicPaymentMethodsDictionary },
        { provide: RECAPTCHA_V3_SITE_KEY, useFactory: () => AppConfigService.settings.captchaSiteKey },
        { provide: RECAPTCHA_LANGUAGE, useFactory: () => Helper.getCookieValue('lang') || 'en' }
    ]
})
export class PortalServicesHelperModule { }
