import { ViewChild, SimpleChanges, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableGridComponent } from '@linkdev/shared-library/lib/core/components/table-grid/table-grid.component';

@Component({
  selector: 'app-related-profile-table',
  templateUrl: './related-profile-table.component.html',
  styleUrls: ['./related-profile-table.component.css']
})
export class RelatedProfileTableComponent implements OnInit {
  @Input() tableContent;
  @Input() totalRecords;
  @Input() tableHeader;
  @Input() keyField;
  @Input() gridTitle;
  @Input() selectedItems;
  @Input() startPage;
  @Output() onBehafeOfSelected = new EventEmitter();
  @Output() onRowClicked = new EventEmitter();

  selectionModeEnum = {
    single: 'single',
    multiple: 'multiple'
  };
  @ViewChild('RelatedProfilesGrid') relatedProfilesGrid: TableGridComponent;
  constructor() { }

  ngOnInit(): void {
  }
  resetTable() {
   this.relatedProfilesGrid?.reset();
  }
  onSelectedItems(items) {
    this.onBehafeOfSelected.emit(items)
  }
  onEditClick(event) {
  }
  onRowClick(event) {
    this.onRowClicked.emit(event);
  }
}
