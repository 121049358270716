import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../core/config/app-config.service';

@Component({
  selector: 'app-chatbot',
  template: '',
})
export class ChatbotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var bubbles_script = document.createElement('script');
    let cookieName = 'lang'
    var language = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')?.pop() || '';
    let bubblesPluginURL = `${AppConfigService.settings.chatbotSrcUrl}${language}`;
    // bubblesPluginURL.async = true;
    bubbles_script.setAttribute('src', bubblesPluginURL);
    document.head.appendChild(bubbles_script);
  }

}