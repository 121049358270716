<section class="banner-section">
  <!-- <a routerLink="/search" class="banner-search-icon d-lg-none"><span class="lib-icon-search"></span></a> -->
  <div class="container">
    <div class="banner-section-wrp">
      <a href="" class="banner-logo d-lg-none">
        <img src="../../assets/images/logo-white.png" alt="" />
      </a>
      <h1 class="banner-title">{{ translation?.homePageTitle || 'E-services Platform'}}</h1>
      <h2 class="banner-brief">
        {{translation?.homePageSubTitle || 'The quickest way to Apply for Services or Book Appointments while sitting at Home'}}
      </h2>
      <a [routerLink]="workspaceLink" class="banner-cta btn btn-primary">
         {{ translation?.goToWorkSpace || 'Go to Workspace'}}
      </a>
    </div>
  </div>
</section>


<nav class="tabs-wrp bg-white homepage-tabs d-none d-lg-block">
  <div class="container">
    <ul class="nav nav-tabs" id="nav-tab" role="tablist">
      <li *ngIf="showServiceCatalog" class="nav-item">
        <a class="nav-link active" id="nav-services-tab" data-toggle="tab" href="#nav-services" role="tab"
           aria-controls="nav-services" aria-selected="true">{{'serviceManagement.serviceCatalog' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="nav-appointment-tab" data-toggle="tab" href="#appointment-content" role="tab"
           aria-controls="nav-services" aria-selected="true">{{'appointments.appointmentServices' | translate}}</a>
      </li>
    </ul>
  </div>
</nav>

<div *ngIf="showServiceCatalog" class="tab-content" id="nav-tabContent">

  <div class="tab-pane fade show active" id="nav-services" role="tabpanel" aria-labelledby="nav-services-tab">
    <div class="container d-none d-lg-block mt-6">
      <div class="section-title-wrp">
        <div class="section-title">
          <h2 class="home-title">
            {{ "serviceManagement.serviceCatalog" | translate }}
          </h2>
        </div>
      </div>
    </div>
    <sm-service-list [pageSize]="6" [isHomepage]="true" (componentLoaded)="serviceCatalogComponentLoaded($event)"></sm-service-list>
  </div>

  <div class="tab-pane fade show" id="appointment-content" role="tabpanel" aria-labelledby="nav-services-tab">
    <div class="container d-none d-lg-block mt-6">
      <div class="section-title-wrp">
        <div class="section-title">
          <h2 class="home-title">
            <!--            please select from available services-->
          </h2>
        </div>
      </div>
    </div>
    <lib-book-appointment class="mt-4 d-block" [isHomepage]="true"></lib-book-appointment>
  </div>

</div>
<router-outlet></router-outlet>
