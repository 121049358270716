import { Injectable } from "@angular/core";
import { AnnouncementComponentItem,AnnouncementComponentsDictionary, SimpleAnnouncementComponent} from '@linkdev/shared-library';
import { RelatedProfileInvitationActionsComponent, TeamMemberInvitationActionsComponent } from "@linkdev/access-manage-library";


@Injectable()
export class DynamicAnnouncemntComponentsDictionary implements AnnouncementComponentsDictionary {
    public getDynamicComponents(): AnnouncementComponentItem[] {
        return this._dynamicComponents;
    }

    private _dynamicComponents: AnnouncementComponentItem[] = [
        new AnnouncementComponentItem(RelatedProfileInvitationActionsComponent, "RelatedProfileInvitationActionsComponent",true),
        new AnnouncementComponentItem(SimpleAnnouncementComponent, "SimpleAnnouncementComponent",false),
        new AnnouncementComponentItem(TeamMemberInvitationActionsComponent, "TeamMemberInvitationActionsComponent",true),
    ]

}
