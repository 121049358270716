import { Component, OnInit, HostListener } from '@angular/core';
import { AccessManagementRoutes } from '@linkdev/access-manage-library';
import { Helper, UserService } from '@linkdev/shared-library';

const styleSheets = {
  portalRtl: '/assets/css/portal-rtl.css',
  portalLtr: '/assets/css/portal-ltr.css',
  portalContrastRtl: '/assets/css/portal-contrast-rtl.css',
  portalContrastLtr: '/assets/css/portal-contrast-ltr.css',
};
@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html'
})
export class SettingsMenuComponent implements OnInit {
  isMobile: boolean = false;
  currentUserName: string;
  fontSelected: string = 'medium-font';
  isContrast: Boolean =
    window.localStorage.getItem('color-theme') === 'contrast-theme'
      ? true
      : false;

  isRtl: Boolean;
  mainCssFile: any;
  switchedCssFile: any;

  profileMenuLink: string = `/${AccessManagementRoutes.baseRoute}/${AccessManagementRoutes.profileMenuRoute}`;

  constructor(private _userService: UserService) {
    this.isMobile = window.innerWidth < 992;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 992;
  }

  ngOnInit(): void {
    this._userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUserName = user.profile.name;
      }
    });

    this.checkPreferredFontSize();
    this.GetCurrentLanguage();
    this.switchedCssFile = document.getElementById('switchedCssFile') ? document.getElementById('switchedCssFile') : null;
  }

  checkPreferredFontSize() {
    const preferredFontSize = window.localStorage.getItem('font-size');
    if (preferredFontSize) {
      this.fontSelected = preferredFontSize;
      document.querySelector('html').classList.add(this.fontSelected);
    }
  }

  resizeFont(fontSize) {
    this.fontSelected = fontSize;
    const htmlEl = document.querySelector('html');
    htmlEl.classList.remove('large-font', 'medium-font', 'small-font');
    htmlEl.classList.add(this.fontSelected);
    window.localStorage.setItem('font-size', this.fontSelected);
  }

  GetCurrentLanguage() {
    let lang = Helper.getCookieValue('lang');
    this.isRtl = lang == 'ar'
    return lang;
  }


  createLinkTag(id) {
    var head = document.head;
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = id;
    head.appendChild(link);
    this[id] = document.getElementById(id);
  }

  switchThemes(e) {
    e.preventDefault();
    if (!document.getElementById("switchedCssFile")) {
      this.createLinkTag('switchedCssFile');
    }
    if (this.isContrast) {
      this.switchedCssFile.setAttribute(
        'href',
        this.isRtl ? styleSheets.portalRtl : styleSheets.portalLtr
      );
      document.querySelector('html').classList.remove('contrast');
      window.localStorage.setItem('color-theme', 'main-theme');
    } else {
      this.switchedCssFile.setAttribute(
        'href',
        this.isRtl
          ? styleSheets.portalContrastRtl
          : styleSheets.portalContrastLtr
      );
      document.querySelector('html').classList.add('contrast');
      window.localStorage.setItem('color-theme', 'contrast-theme');
    }
    this.isContrast = !this.isContrast;
  }
}
